"use client";
import { iPayInvoicePage } from "@/app/models/pageTypes/payInvoicePage";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useShallow } from "zustand/react/shallow";
import useStore from "@/stores/use-store";
import { PaymentorderResponseRoot } from '@/app/models/apiModels/startPaymentResponse';
import { CaptureResponse } from "@/app/models/apiModels/captureResponse";
import { PaymentPaidResponse } from "@/app/models/apiModels/paymentPaidResponse";
import { useEffect, useState } from "react";
import { redirect } from "next/navigation";
import Loader from "@/app/components/Loader/Loader";
import { useRouter } from "next/navigation";
import LinkElement from "@/app/components/General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import { CancelResponse } from "@/app/models/apiModels/cancelResponse";
import RichText from "@/app/components/RichText/RichText";
import styles from './payinvoicepage.module.scss';

interface iPayInvoicePageProps {
  pageData: iPayInvoicePage;
}

export default function PayInvoicePage({ pageData }: iPayInvoicePageProps) {

  const biglocalstorage = doUseOrderStorageStore(useShallow((state) => state));
  const [errorDetected, setErrorDetected] = useState<boolean>(false);
  const [redirectCheckoutHref, setRedirectCheckoutHref] = useState("");
  const [paidResponse, setPaidResponse] = useState<PaymentPaidResponse | null>(null);
  const [sessionStatus, setSessionStatus] = useState<string>();
  const documentIdinit = pageData.searchParams?.documentId as string;
  const [documentIdStatus, setDocumentIdStatus] = useState(documentIdinit);
  const customerIdinit = pageData.searchParams?.customerId as string;
  const [customerId, setCustomerId] = useState(customerIdinit);
  const router = useRouter();
  const translations = pageData.requestContext.translations;
  const [messageTitle, setMessageTitle] = useState("");
  const [messageText, setMessageText] = useState("");
  const [messageButtonText, setMessageButtonText] = useState("");
  const [messageButtonUrl, setMessageButtonUrl] = useState("");
  const [messageButtonFunctionName, setMessageButtonFunctionName] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [startedPayment, setStartedPayment] = useState(false);
  const [startedPaymentPaidCheck, setStartedPaymentPaidCheck] = useState(false);
  const [startedPaymentCapture, setStartedPaymentCapture] = useState(false);
  const [startedPaymentSetInvoiceAsPaid, setStartedPaymentSetInvoiceAsPaid] = useState(false);

  useEffect(() => {
    if (pageData.searchParams && pageData.searchParams.sessionStatus) {
      setSessionStatus(pageData.searchParams.sessionStatus as string);
    } else {
      setSessionStatus("notStarted");
    }
  }, []);

  useEffect(() => {
    if ((pageData.properties.isloggedin || !pageData.properties.isloggedin) && biglocalstorage.invoicePayment !== undefined) {
      if (sessionStatus === "cancel") {
        setMessage(
          translation.pages.order.paymentContainer.payInvoicePaymentCancelledTitle,
          translation.pages.order.paymentContainer.payInvoicePaymentCancelledText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
        return
      }

      if ((pageData.properties.isloggedin === undefined || !pageData.properties.isloggedin) && sessionStatus !== "cancel") {
        location.reload();
      }

      if (pageData.properties.isloggedin && sessionStatus === "notStarted" && !startedPayment) {
        setStartedPayment(true);
        setMessage(
          translation.pages.order.paymentContainer.payInvoiceMessageTitle,
          translation.pages.order.paymentContainer.payInvoiceMessageText,
          translation.pages.order.paymentContainer.payInvoiceButtonText,
          null,
          "startPayment"
        );
      }

      if (pageData.properties.isloggedin && sessionStatus === "waiting" && biglocalstorage.invoicePayment.paymentOrderId !== "" && !startedPaymentPaidCheck) {
        setStartedPaymentPaidCheck(true);
        checkPaymentPaid(biglocalstorage.invoicePayment.paymentOrderId);
        setStartedPaymentPaidCheck(false);
      }

      if (pageData.properties.isloggedin && sessionStatus === "paymentOK" && paidResponse?.paid && !startedPaymentCapture) {
        setStartedPaymentCapture(true);
        capturePayment();
        setStartedPaymentCapture(false);
      }

      if (pageData.properties.isloggedin && sessionStatus === "captureOK" && !startedPaymentSetInvoiceAsPaid) {
        setStartedPaymentSetInvoiceAsPaid(true);
        setInvoiceAsPaid(paidResponse);
        setStartedPaymentSetInvoiceAsPaid(false);
      }
    }
  }, [sessionStatus, paidResponse]);

  const setMessage = (title: string, text: string, buttonText: string, buttonUrl: string | null | undefined = undefined, buttonActionName: string | null | undefined = undefined) => {
    setMessageTitle(title);
    setMessageText(text);
    setMessageButtonText(buttonText);
    if (buttonUrl) {
      setMessageButtonUrl(buttonUrl);
    }
    if (buttonActionName) {
      setMessageButtonFunctionName(buttonActionName);
    }
    setShowMessage(true);
    setIsLoading(false);
  }

  const hideMessage = () => {
    setMessageTitle("");
    setMessageText("");
    setMessageButtonText("");
    setMessageButtonUrl("");
    setMessageButtonFunctionName("");
    setShowMessage(false);
  }

  let translation = pageData.requestContext.translations;
  const isoCulture = `${pageData.requestContext.language.toLowerCase()}-${pageData.requestContext.country.toUpperCase()}`;
  const setValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setInvoicePaymentValue;

  // Start paymnet
  const startPayment = async () => {
    try {
      const userInvoiceResponse = await fetch(`/api/user/getUserInvoices?customerId=${customerId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
        },
      })

      if (!userInvoiceResponse.ok) {
        clientLogError("PayInvoicePage.tsx", "startpaymentInvoice", userInvoiceResponse);
        setSessionStatus("startingPaymentFailed");
        setStartedPayment(false);
        setMessage(
          translation.pages.order.paymentContainer.startpayNotOK,
          translation.pages.order.paymentContainer.startInvoicepayNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
        return;
      }

      const customerInvoices = await userInvoiceResponse.json()
      const currentInvoice = customerInvoices.find((invoice: any) => invoice.documentid === documentIdStatus);
      const payeeReference = `INV00${Math.floor((Math.random() * (12345678910 - 1234567891 + 1)) + 1234567891)
        .toString()
        .replace(/[-:.TZ]/g, "")
        .slice(2, 16)}`;
      const formData = {
        payeeReference: payeeReference,
        spaceManagerInvoiceDocumentId: documentIdStatus,
        language: isoCulture
      };

      const response = await fetch("/api/pay/startPaymentInvoice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
          hostName: "https://" + window.location.host + "/" + pageData.requestContext.country,
          customerId: customerId,
          userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36", //TODO BYT UT Till riktig useragent
          purchaseInformation: translation.pages.order.purchaseInformation,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        setStartedPayment(false);
        setSessionStatus("startingPaymentFailed");
        setMessage(
          translation.pages.order.paymentContainer.startpayNotOK,
          translation.pages.order.paymentContainer.startInvoicepayNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );

        return
      }

      const paymentOrder: PaymentorderResponseRoot = await response.json();
      setValue?.("paymentOrderId", paymentOrder.paymentorder.id);
      setValue?.("invNumber", currentInvoice?.invNum);
      setValue?.("siteId", currentInvoice?.siteId);
      setValue?.("customerId", customerId);
      setValue?.("documentId", documentIdStatus);
      setValue?.("paymentMethod", "C6");
      setValue?.("paymentReference", payeeReference);

      const redirectCheckoutOperation = paymentOrder.operations.find(
        (operation) => operation.rel === 'redirect-checkout'
      );

      if (redirectCheckoutOperation) {
        setStartedPayment(false);
        setRedirectCheckoutHref(redirectCheckoutOperation.href);
      } else {
        setSessionStatus("startingPaymentFailed");
        setStartedPayment(false);
        setMessage(
          translation.pages.order.paymentContainer.startpayNotOK,
          translation.pages.order.paymentContainer.startInvoicepayNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
      }
    } catch (error) {
      clientLogError("PayInvoicePage.tsx", "startpaymentInvoice", error);
      setStartedPayment(false);
      setSessionStatus("startingPaymentFailed");
      setMessage(
        translation.pages.order.paymentContainer.startpayNotOK,
        translation.pages.order.paymentContainer.startInvoicepayNotOKText,
        translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
        pageData.requestContext.menu.myPagesLoggedInLink.url
      );
    }
  };

  // Check status of the payment
  const checkPaymentPaid = async (paymentIdUrl: string) => {
    if (!paymentIdUrl || paymentIdUrl === "") {
      setStartedPaymentPaidCheck(false);
      setSessionStatus("paymentFailed");
      setMessage(
        translation.pages.order.paymentContainer.paidNotOK,
        translation.pages.order.paymentContainer.paidInvoiceNotOKText,
        translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
        pageData.requestContext.menu.myPagesLoggedInLink.url
      );
      return;
    }

    try {
      const response = await fetch("/api/pay/paidPaymentByIdUrl", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
          PaymentIdUrl: paymentIdUrl,
        },
      });

      if (!response.ok) {
        setStartedPaymentPaidCheck(false);
        setSessionStatus("paymentFailed");
        setMessage(
          translation.pages.order.paymentContainer.paidNotOK,
          translation.pages.order.paymentContainer.paidInvoiceNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
        return;
      }

      const data: PaymentPaidResponse = await response.json();

      if (data?.paid) {
        setPaidResponse(data);
        setSessionStatus("paymentOK");
      }
      else {
        setStartedPaymentPaidCheck(false);
        setSessionStatus("paymentFailed");
        setMessage(
          translation.pages.order.paymentContainer.paidNotOK,
          translation.pages.order.paymentContainer.paidInvoiceNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
      }
    } catch (error) {
      setStartedPaymentPaidCheck(false);
      clientLogError("PayInvoicePage.tsx", "checkPaymentPaid", error);
    }
  };

  // Capture payment and rent unit
  const capturePayment = async () => {
    if (paidResponse) {
      try {
        const formData = {
          paymentOrderId: biglocalstorage.invoicePayment.paymentOrderId,
          spaceManagerInvoiceInvNum: biglocalstorage.invoicePayment.invNumber,
          spaceManagerInvoiceDocumentId: biglocalstorage.invoicePayment.documentId,
          customerId: biglocalstorage.invoicePayment.customerId,
          payeeReference: `CAP00${Math.floor((Math.random() * (12345678910 - 1234567891 + 1)) + 1234567891)
            .toString()
            .replace(/[-:.TZ]/g, "")
            .slice(2, 16)}`,
          purchaseInformation: translation.pages.order.purchaseInformation,
        };

        const response = await fetch("/api/pay/capturePaymentInvoice", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          setStartedPaymentCapture(false);
          setSessionStatus("captureFailed");
          await cancelPayment();
          return;
        }

        const capture: CaptureResponse = await response.json();

        if (capture.capture.transaction.state === 'Completed') {
          setSessionStatus("captureOK");
        } else {
          setStartedPaymentCapture(false);
          setSessionStatus("captureFailed");
          await cancelPayment();
        }
      } catch (error) {
        clientLogError("PayInvoicePage.tsx", "capturePayment", error);
        setStartedPaymentCapture(false);
        setSessionStatus("captureFailed");
        await cancelPayment();
      }
    }
    else {
      clientLogError("PayInvoicePage.tsx", "capturePayment");
      setStartedPaymentCapture(false);
      setSessionStatus("captureFailed");
      await cancelPayment();
    }
  };

  const setInvoiceAsPaid = async (paymentPaidResponse: PaymentPaidResponse | null) => {
    try {
      let payInvoiceData = {
        customerId: biglocalstorage.invoicePayment.customerId,
        siteId: biglocalstorage.invoicePayment.siteId,
        documentId: biglocalstorage.invoicePayment.documentId,
        paymentMethod: biglocalstorage.invoicePayment.paymentMethod,
        paymentReference: biglocalstorage.invoicePayment.paymentReference,
      };

      const payInvoiceResponse = await fetch("/api/pay/payInvoice", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify(payInvoiceData),
      });

      if (!payInvoiceResponse.ok) {
        setStartedPaymentSetInvoiceAsPaid(false);
        setErrorDetected(true);
        const dataString = `Data: 
        customerId: ${payInvoiceData.customerId}, 
        siteId: ${payInvoiceData.siteId}, 
        documentId: ${payInvoiceData.documentId},
        paymentMethod: ${payInvoiceData.paymentMethod},
        paymentReference: ${payInvoiceData.paymentReference}`;
        clientLogError("PayInvoicePage.tsx", "setInvoiceAsPaid", "Could not save invoice payment to spaceApi", dataString, "Reversing payment");
        setSessionStatus("saveToSpaceFailed");

        await reversePayment();
        return;
      }

      const payInvoiceResponseData = await payInvoiceResponse.json();
      setMessage(
        translation.pages.order.paymentContainer.payInvoiceMessageSuccessTitle,
        translation.pages.order.paymentContainer.payInvoiceMessageSuccessText,
        translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
        pageData.requestContext.menu.myPagesLoggedInLink.url
      );
    }
    catch (error) {
      setStartedPaymentSetInvoiceAsPaid(false);
      clientLogError("PayInvoicePage.tsx", "setInvoiceAsPaid", "PaymentPaidResponse:", paymentPaidResponse, "Error:", error);
      setSessionStatus("saveToSpaceFailed");
      await reversePayment();
    }
  };

  const reversePayment = async () => {
    try {
      const formData = {
        paymentOrderId: biglocalstorage.invoicePayment.paymentOrderId,
        country: pageData.requestContext.country,
        spaceManagerInvoiceDocumentId: biglocalstorage.invoicePayment.documentId,
        customerId: biglocalstorage.invoicePayment.customerId,
      };

      const response = await fetch("/api/pay/reverseInvoicePayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        clientLogError("PayInvoicePage.tsx", "reversePayment", `reverse response not OK for customer: ${biglocalstorage.invoicePayment.customerId}`);
        setMessage(
          translation.pages.order.paymentContainer.capOKrevNotOK,
          translation.pages.order.paymentContainer.capInvoiceOKrevNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
        return;
      }

      const paymentReverseOrder = await response.json();
      if (paymentReverseOrder.reversal.transaction.state === 'Completed') {
        clientLogError("PayInvoicePage.tsx", "reversePayment", `reverse completed for customer: ${biglocalstorage.invoicePayment.customerId}`);
        setMessage(
          translation.pages.order.paymentContainer.capOKrevOK,
          translation.pages.order.paymentContainer.capInvoiceOKrevOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
        return;
      } else {
        clientLogError("PayInvoicePage.tsx", "reversePayment", `reverse not completed for customer: ${biglocalstorage.invoicePayment.customerId}`);
        setMessage(
          translation.pages.order.paymentContainer.capOKrevNotOK,
          translation.pages.order.paymentContainer.capInvoiceOKrevNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
      }
    } catch (error) {
      clientLogError("PayInvoicePage.tsx", "reversePayment", `reverse failed for customer: ${biglocalstorage.invoicePayment.customerId}`, error);
      setMessage(
        translation.pages.order.paymentContainer.capOKrevNotOK,
        translation.pages.order.paymentContainer.capInvoiceOKrevNotOKText,
        translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
        pageData.requestContext.menu.myPagesLoggedInLink.url
      );
    }
  }

  const cancelPayment = async () => {
    if (paidResponse) {
      try {
        const response = await fetch("/api/pay/cancelPayment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Country: pageData.requestContext.country,
            spaceManagerInvoiceInvNum: biglocalstorage.invoicePayment.invNumber,
            cancelurl: biglocalstorage.invoicePayment.paymentOrderId,
          },
        });

        if (!response.ok) {
          clientLogError("PayInvoicePage.tsx", "cancelPayment", `cancel response not OK for customer: ${biglocalstorage.invoicePayment.customerId}`);
          setMessage(
            translation.pages.order.paymentContainer.capNotOK,
            translation.pages.order.paymentContainer.capInvoiceOKcanNotOKText,
            translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
            pageData.requestContext.menu.myPagesLoggedInLink.url
          );
          return;
        }

        const cancel: CancelResponse = await response.json();

        if (cancel.cancellation.transaction.state === 'Completed') {
          clientLogError("PayInvoicePage.tsx", "cancelPayment", `cancel completed for customer: ${biglocalstorage.invoicePayment.customerId}`);
          setMessage(
            translation.pages.order.paymentContainer.capOKcanOK,
            translation.pages.order.paymentContainer.capInvoiceOKcanOKText,
            translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
            pageData.requestContext.menu.myPagesLoggedInLink.url
          );
        } else {
          clientLogError("PayInvoicePage.tsx", "cancelPayment", `cancel failed for customer: ${biglocalstorage.invoicePayment.customerId}`);
          setMessage(
            translation.pages.order.paymentContainer.capNotOK,
            translation.pages.order.paymentContainer.capInvoiceOKcanNotOKText,
            translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
            pageData.requestContext.menu.myPagesLoggedInLink.url
          );
        }
      } catch (error) {
        clientLogError("PayInvoicePage.tsx", "cancelPayment", `cancel failed for customer: ${biglocalstorage.invoicePayment.customerId}`, error);
        setMessage(
          translation.pages.order.paymentContainer.capNotOK,
          translation.pages.order.paymentContainer.capInvoiceOKcanNotOKText,
          translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
          pageData.requestContext.menu.myPagesLoggedInLink.url
        );
      } finally {
      }
    }
    else {
      clientLogError("PayInvoicePage.tsx", "cancelPayment", `no paid response to cancel, cancel failed for customer: ${biglocalstorage.invoicePayment.customerId}`);
      setMessage(
        translation.pages.order.paymentContainer.capNotOK,
        translation.pages.order.paymentContainer.capInvoiceOKcanNotOKText,
        translation.pages.order.paymentContainer.payInvoiceButtonTextToMyPage,
        pageData.requestContext.menu.myPagesLoggedInLink.url
      );
    }
  }




  useEffect(() => {
    if (biglocalstorage.invoicePayment.paymentOrderId !== "" && redirectCheckoutHref !== "") {
      redirect(redirectCheckoutHref);
    }
  }, [biglocalstorage.invoicePayment.paymentOrderId, redirectCheckoutHref]);

  useEffect(() => {
    if (customerId && customerId !== "") {
      setValue?.("customerId", customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (sessionStatus === "completed" && pageData.properties.isloggedin === undefined) {
      router.refresh();
    }
  }, [sessionStatus, pageData.properties.isloggedin]);

  const handleButtonClick = () => {
    setIsLoading(true);
    if (messageButtonUrl && messageButtonUrl !== "") {
      router.push(messageButtonUrl);
    } else if (messageButtonFunctionName && messageButtonFunctionName !== "") {
      switch (messageButtonFunctionName) {
        case "startPayment":
        default:
          startPayment();
          break;
      }
    }
  }

  return (
    <main className={`${styles.myPagesPage} pb-10 lg:pb-20 pt-10`}>
      <div className="container mx-auto" >
        <h1 className={`mb-10`}>{pageData.properties.title}</h1>

        <div>
          <Loader loading={isLoading} useFullWindow={true} />
          {showMessage && (
            <div className="rounded-3xl bg-white lg:w-[400px] p-10 mb-10 mx-auto">
              <div>
                <h2>{messageTitle}</h2>
                <div className="mb-8">{messageText}</div>
                <LinkElement
                  title={messageButtonText}
                  buttonColorTheme={eButtonColorTheme.Green}
                  chevronPosition={eChevronPosition.None}
                  onClick={handleButtonClick}
                  isButton={true}
                  className="w-full"
                >
                  {messageButtonText}
                </LinkElement>

                {(pageData.requestContext.configurations.acceptedPaymentCardsImageUrls && (sessionStatus === "notStarted" || !sessionStatus)) && (
                  <div className={`flex justify-center space-x-2 mt-5`}>
                    {pageData.requestContext.configurations.acceptedPaymentCardsImageUrls?.map((imageUrl, index) => (
                      <img
                        key={index}
                        src={imageUrl}
                        className={`inline-block max-h-[24px]`}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {pageData.properties.mainBody && (
          <div className="rounded-3xl bg-white p-10 mb-10">
            <RichText text={pageData.properties.mainBody} />
          </div>
        )}
      </div>
    </main >
  )
}
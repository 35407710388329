'use client';

import React, { useEffect, useState } from 'react';
import styles from './recruitafriend.module.scss';
import Modal from '../Modal/Modal';
import Loader from '../Loader/Loader';
import LinkElement from '../General/LinkElement';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { eChevronColor } from '@/app/models/enum/chevronColor';
import { iRecruitModel } from '@/app/models/recruitModel';

interface RecruitAFriendProps {
    translations: any;
    model: iRecruitModel;
}

const RecruitAFriend: React.FC<RecruitAFriendProps> = ({ translations, model }) => {
    const [isRecruitAFriendModalOpen, setIsRecruitAFriendModalOpen] = useState(false);
    const [recruitMailLink, setRecruitMailLink] = useState('');
    const [recruitLink, setRecruitLink] = useState('');

    if (!model.recruitReferenceId || !model.userForename || !model.userSurname || !model.emailBody || !model.emailSubject) {
        return <></>;
    }

    useEffect(() => {
        const url = `${model.baseUrl}?refId=${model.recruitReferenceId}`;
        setRecruitLink(url);
        const body = model.emailBody?.replaceAll("[forename]", model.userForename ?? "").replaceAll("[surname]", model.userSurname ?? "").replaceAll("[url]", url);
        const subject = model.emailSubject?.replaceAll("[forename]", model.userForename ?? "").replaceAll("[surname]", model.userSurname ?? "").replaceAll("[url]", url);
        const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
        setRecruitMailLink(mailtoLink);
    }, []);

    const handleOpenRecruitAFriendModal = () => {
        setIsRecruitAFriendModalOpen(true);
    };

    const handleCloseRecruitAFriendModal = () => {
        setIsRecruitAFriendModalOpen(false);
    };

    return (
        <>
            <div className={`w-full lg:w-auto flex items-center justify-end`}>
                <LinkElement
                    title={model.buttonText}
                    buttonColorTheme={eButtonColorTheme.Green}
                    chevronPosition={eChevronPosition.None}
                    isButton={true}
                    onClick={handleOpenRecruitAFriendModal}
                >
                    {model.buttonText}
                </LinkElement>
            </div>

            <Modal
                isOpen={isRecruitAFriendModalOpen}
                onClose={handleCloseRecruitAFriendModal}
                closeTranslation={translations.general.closePopup}
                className='w-600-responsive' >
                <div>
                    <div>
                        <h2>{model.modalTitle}</h2>
                        <div>{model.modalFirstText}</div>
                        <div className="my-4">
                            <LinkElement
                                title={model.modalLinkText}
                                chevronColor={eChevronColor.Green}
                                chevronPosition={eChevronPosition.None}
                                href={recruitMailLink}
                                className="text-normal"
                            >
                                {model.modalLinkText}
                            </LinkElement>
                        </div>
                        <div>{model.modalSecondText}</div>
                        <div className="my-4">
                            <LinkElement
                                title={recruitLink}
                                chevronPosition={eChevronPosition.None}
                                className={`${styles.recruitLink} text-normal`}
                                href={recruitLink}
                            >
                                {recruitLink}
                            </LinkElement>
                        </div>
                        <LinkElement
                            title={translations.general.close}
                            isButton={true}
                            onClick={handleCloseRecruitAFriendModal}
                            buttonColorTheme={eButtonColorTheme.Green}
                            className="w-full mt-6"
                        >
                            {translations.general.close}
                        </LinkElement>

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RecruitAFriend;
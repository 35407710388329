import { StateCreator } from "zustand";

export type RecruitAFriendState = {
  recruitAFriend: {
    referenceId: string;
  };
};

export type RecruitAFriendActions = {
  setRecruitAFriendValue: (key: keyof RecruitAFriendState["recruitAFriend"], value: any) => void;
  resetRecruitAFriend: () => void;
};

const initialState: RecruitAFriendState = {
  recruitAFriend: {
    referenceId: ""
  },
};
export const createRecruitAFriendSlice: StateCreator<RecruitAFriendState & RecruitAFriendActions> = (
  set
) => ({
  recruitAFriend: {
    referenceId: ""
  },
  setRecruitAFriendValue: (key, value) =>
    set((state) => ({
      recruitAFriend: {
        ...state.recruitAFriend,
        [key]: value,
      },
    })),
    resetRecruitAFriend: () => set(() => initialState),
});

"use client";

import React from "react";
import styles from "./login.module.scss";
import { clientLogError, clientLogInfo, clientLogWarn } from "@/app/helpers/clientLoggingHelper";
import { useEffect, useState } from "react";
import Loader from "@/app/components/Loader/Loader";
import { useRouter } from "next/navigation";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useShallow } from "zustand/react/shallow";
import useStore from "@/stores/use-store";
import FormInput from "../Forms/FormInput";
import LinkElement from "../General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import Modal from "../Modal/Modal";
import { trim } from "lodash";

export interface Props {
    sessionStatus: string | string[] | undefined;
    sessionId: string | string[] | undefined;
    isLoggedIn: boolean;
    myPagesLink: string;
    country: string;
    translations: any;
}

interface LoginResponse {
    data: {
        errors: [];
        id: string;
        redirect_url: string;
        status: string;
    };
}

export default function Login({ sessionStatus, sessionId, isLoggedIn, myPagesLink, country, translations }: Props) {
    const setValue = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.setSessionValue;

    const setUserValue = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.setUserValue;

    const [requestSent, setRequestSent] = useState<boolean>(false);
    const [uniqueId, setUniqueId] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [errorButtonText, setErrorButtonText] = useState<string>("");
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const user = doUseOrderStorageStore(useShallow((state) => state.user));
    const [loading, setLoading] = useState<boolean>(sessionStatus === "Finished" && sessionId !== undefined && sessionId !== null && user?.personalNumber !== undefined);
    const session = doUseOrderStorageStore(useShallow((state) => state.session));
    const [mydata, setMyData] = useState<LoginResponse | null>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const router = useRouter();

    //STEP 1: Check if user is logged in
    useEffect(() => {
        if (isLoggedIn) {
            clientLogInfo("LoginUserPage.tsx", "isLoggedIn", `User is logged in, redirecting to "${myPagesLink}"`);
            router.push(myPagesLink)
        }
    }, [isLoggedIn]);

    function isValidCPR(cpr: string): boolean {
        //TODO: Add more validation if needed
        return cpr.length === 10;
    }

    function isOver18(cpr: string): boolean {
        const day = parseInt(cpr.substring(0, 2), 10);
        const month = parseInt(cpr.substring(2, 4), 10);
        const year = parseInt(cpr.substring(4, 6), 10);

        const currentYear = new Date().getFullYear();
        const fullYear = year + (year <= currentYear % 100 ? 2000 : 1900);

        const birthDate = new Date(fullYear, month - 1, day);

        var age = new Date().getFullYear() - birthDate.getFullYear();
        const monthDiff = new Date().getMonth() - birthDate.getMonth();
        const dayDiff = new Date().getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age >= 18;
    }

    //Go to mitid  
    const handleSubmit = async () => {
        try {
            clientLogInfo("LoginUserPage.tsx", "handleSubmit", "handling submit");
            setRequestSent(false);
            const trimmedUniqueId = uniqueId.trim();

            if (!isValidCPR(trimmedUniqueId)) {
                clientLogWarn("LoginUserPage.tsx", "handleSubmit", "Invalid personal number", `Personal number: ${trimmedUniqueId}`);
                initError(
                    translations.pages.loginPage.errors.wrongPersonalNumberTitle,
                    translations.pages.loginPage.errors.wrongPersonalNumberText
                )
                return;
            }

            if (!isOver18(trimmedUniqueId)) {
                clientLogWarn("LoginUserPage.tsx", "handleSubmit", "User is under 18", `Personal number: ${trimmedUniqueId}`);
                initError(
                    translations.pages.loginPage.errors.mustBe18Title,
                    translations.pages.loginPage.errors.mustBe18Text
                )
                return;
            }

            setLoading(true);

            clientLogInfo("LoginUserPage.tsx", "handleSubmit", "URL", window.location.href, `Sending request to Zignsec MITID API with personal number: ${trimmedUniqueId}`);
            const fullUrl = window.location.href;
            const urlObj = new URL(fullUrl);
            const returnUrl = urlObj.pathname + urlObj.search + urlObj.hash;

            const response = await fetch(`/api/login/mitid`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Country: country,
                },
                body: JSON.stringify({
                    cpr: trimmedUniqueId,
                    url: returnUrl,
                    mitidText: translations.pages.order.paymentContainer.mitidTextBody,
                }),
            });

            if (!response.ok) {
                var responseData = await response.json();
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                )
            }

            const data = await response.json();
            clientLogInfo("LoginUserPage.tsx", "handleSubmit", `User personal number: ${trimmedUniqueId}`);
            if (trimmedUniqueId !== "") {
                clientLogInfo("LoginUserPage.tsx", "handleSubmit", "myData", data);
                setUserValue?.("personalNumber", trimmedUniqueId);
                setMyData(data);
                setRequestSent(true);
            } else {
                initError(
                    translations.pages.loginPage.errors.mitidNotOK,
                    translations.pages.loginPage.errors.mitidNotOKText
                )
            }
        } catch (error) {
            clientLogError("LoginUserPage.tsx", "handleSubmit", "Error", error);
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
        }
    }

    //STEP 2/6 - loginuser if authentication is successfull
    useEffect(() => {
        if (sessionStatus === "Finished" && sessionId && user.personalNumber && !isLoggingIn) {
            setIsLoggingIn(true);
            clientLogInfo("LoginUserPage.tsx", `sessionStatus: ${sessionStatus}, sessionId: ${sessionId}, user.personalNumber: ${user.personalNumber}`, "session", session);
            setValue?.("sessionId", sessionId.toString());
            onLoginSubmit(user.personalNumber);
        } else if (sessionStatus === "Finished" && sessionId && user.personalNumber && !isLoggingIn) {

        } else if (sessionStatus === "Failed") {
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
        }
    }, [sessionStatus, sessionId, user.personalNumber, session.sessionId]);

    //STEP 3 - send to login zignsec
    useEffect(() => {
        if (mydata?.data.redirect_url && requestSent === true) {
            clientLogInfo("LoginUserPage.tsx", `redirecting to "${mydata.data.redirect_url}"`, "mydata", mydata);
            router.push(mydata.data.redirect_url);
        } else if (!mydata?.data.redirect_url) {
            clientLogError("LoginUserPage.tsx", "redirect mydata", "Redirect URL is not defined");
        } else {
            setLoading(false);
            clientLogError("LoginUserPage.tsx", "redirect mydata", "Request has not been sent");
        }
    }, [mydata]);

    // STEP 5 - Get spacemanager user by identity number
    const GetSpaceManagerUsersByIdentityNumber = async (uniqueIdentifier: string) => {
        clientLogInfo("LoginUserPage.tsx", "GetSpaceManagerUsersByIdentityNumber", `Getting user with unique identifier: ${uniqueIdentifier}`);
        const timespan = new Date().getTime();

        const useresponse = await fetch(`/api/user/getSpaceManagerUsersByIdentityNumber?timespan=${timespan}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                personalNumber: uniqueIdentifier,
                filter: "false",
                companyNumber: "",
                Country: country,
            },
        });

        if (!useresponse.ok) {
            clientLogError("LoginUserPage.tsx", "GetSpaceManagerUsersByIdentityNumber", "Network response was not ok");
            setLoading(false);
            throw new Error("Network response was not ok");
        }
        return await useresponse.json();
    };

    //STEP 4 - login user
    const onLoginSubmit = async (uniqueIdentifier: string) => {
        clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", `Logging in user with unique identifier: ${uniqueIdentifier}`);
        if (!uniqueIdentifier || uniqueIdentifier === "") {
            clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", "Please enter a unique identifier");
            initError(
                translations.pages.loginPage.errors.mitidNotOK,
                translations.pages.loginPage.errors.mitidNotOKText
            )
            return;
        }

        setLoading(true);

        try {

            const users = await GetSpaceManagerUsersByIdentityNumber(uniqueIdentifier);
            clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", "userresponse", users);
            const timespan = new Date().getTime();

            if (users && users.length > 0) {
                const response = await fetch(`/api/user/login?timespan=${timespan}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Country: country,
                    },
                    body: JSON.stringify({ sessionId: sessionId, uniqueIdentifier: uniqueIdentifier }),
                });


                if (!response.ok) {
                    clientLogError("LoginUserPage.tsx", "onLoginSubmit", "Network response was not ok");
                    throw new Error("Network response was not ok");
                }

                setRequestSent(true);
                router.push(myPagesLink);
            } else if (!users || users.length === 0) {
                initError(
                    translations.pages.loginPage.errors.userNotExists,
                    translations.pages.loginPage.errors.userNotExistsText,
                    translations.general.close
                )
                return;
            }


        } catch (error) {
            clientLogError("LoginUserPage.tsx", "onLoginSubmit", "Error", error);
            initError(
                translations.pages.loginPage.errors.loginNotOK,
                translations.pages.loginPage.errors.loginNotOKText
            )
        } finally {
            clientLogInfo("LoginUserPage.tsx", "onLoginSubmit", "finally");
        }
    };

    const clearError = async () => {
        setShowError(false);
        setErrorTitle("");
        setErrorText("");
    }
    const initError = (title: string = "", message: string = "", buttonText = "") => {
        if (title === "") {
            title = translations.general.errors.e500.title;
        }

        if (message === "") {
            message = translations.general.errors.e500.message;
        }

        if (buttonText === "") {
            buttonText = translations.pages.loginPage.errors.tryAgain;
        }

        setErrorTitle(title);
        setErrorText(message);
        setErrorButtonText(buttonText);
        setLoading(false);
        setShowError(true);
    }

    return (
        <div className="flex flex-col space-y-4">
            <Loader loading={loading} useFullWindow={true} useNoBackground={false} />
            <Modal
                isOpen={showError}
                onClose={clearError}
                closeTranslation={translations.general.closePopup}
            >
                <div>
                    <h2>{errorTitle}</h2>
                    <div>{errorText}</div>
                    <LinkElement
                        title={errorButtonText}
                        isButton={true}
                        onClick={clearError}
                        buttonColorTheme={eButtonColorTheme.DarkContrast}
                        className="w-full mt-6"
                    >
                        {errorButtonText}
                    </LinkElement>

                </div>
            </Modal>
            <div>
                <FormInput
                    placeholder={translations.pages.loginPage.personalIdentificationNumber}
                    type="text"
                    name="uniqueId"
                    label={translations.pages.loginPage.personalIdentificationNumber}
                    className="w-full"
                    onChange={(e) => {
                        if (typeof e === 'string') {
                            setUniqueId(e);
                        } else {
                            setUniqueId(e.target.value);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (typeof e === 'string') {
                            if (e === "Enter") {
                                handleSubmit(); //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                            }
                        } else if (e.key === "Enter") {
                            handleSubmit(); //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                        }
                    }}
                />
            </div>
            <div>
                <LinkElement
                    title={translations.general.login}
                    isButton={true}
                    onClick={() => handleSubmit()} //SKIPMITID ändra till onLoginSubmit och lägg till uniqueId
                    buttonColorTheme={eButtonColorTheme.Green}
                    className={`w-full`}
                >
                    {translations.general.loginButton}
                </LinkElement>
            </div>
        </div>
    );
}

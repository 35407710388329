'use client';
import { iFAQBlock } from '@/app/models/blockTypes/faqBlock';
import styles from './faqblock.module.scss';
import RichText from '@/app/components/RichText/RichText';
import Accordion from '@/app/components/Accordion/Accordion';
import { GetBackgroundColorClass } from '@/app/helpers/clientUtilityHelper';
import { clientLogError } from '@/app/helpers/clientLoggingHelper';

interface Props {
    block: iFAQBlock;
    translations: any;
    currentUrl: string;
    isInGroup?: boolean;
}


export default function FAQBlock({ block, translations, currentUrl, isInGroup = false }: Props) {
    try {
        return (
            <div className={`${isInGroup ? "" : "px-6 xl:px-0"}`}>
                <Accordion title={block.properties.question} translations={translations} className={`rounded-[0.5rem] ${GetBackgroundColorClass(block)}`}>
                    {block.properties.answer && (
                        <RichText text={block.properties.answer} className={`${styles.expandableText}`} />
                    )}
                </Accordion>
            </div>
        );
    } catch (error) {
        clientLogError("FAQBlock.tsx", 'Error when generating FAQBlock', `current url: ${currentUrl}`, 'Error', error);
    }
}

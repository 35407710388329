'use client';
import React, { useEffect } from "react";
import Image from "next/image";
import { eAreaType } from "@/app/models/enum/areaType";
import { getAreasByType } from "@/app/helpers/areaHelper";
import FooterAreaAccordion from "../FooterAreaAccordion/FooterAreaAccordion";
import { iAreaSimpelModel } from "../../models/apiModels/areaSimpel"; //"../models/apiModels/areaSimpel";
import styles from "./footer.module.scss";
import SocialLinks from "../SocialLinks/SocialLinks";
import Accordion from "../Accordion/Accordion";
import { logError } from "@/app/helpers/azureBlobLogger";
import { iCultureItem } from "@/app/models/iCultureItem";
import { iRegionalFooter } from "@/app/models/apiModels/regionalFooter";
import { iRegionalFooterContent } from "@/app/models/apiModels/regionalFooterContent";
import Loader from "../Loader/Loader";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";

interface Props {
  country: string;
  areas: iAreaSimpelModel[];
  language: string;
  existingLanguages?: iCultureItem[]
  translations: any;
}

export default function ClientFooter({ country, language, areas, existingLanguages, translations }: Props) {
  const [regionalFooter, setRegionalFooter] = React.useState<iRegionalFooter>();
  const [languageCode, setLanguageCode] = React.useState<string>();
  const [currentLanguageColumns, setCurrentLanguageColumns] = React.useState<any[]>([]);
  const [content, setContent] = React.useState<iRegionalFooterContent>();
  const [columns, setColumns] = React.useState<iAreaSimpelModel[][]>();
  const [otherLanguage, setOtherLanguage] = React.useState<iCultureItem>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const getISOCultureCode = (cultureString: string): string => {
    var cultureCode = "";

    if (cultureString.includes("-") || cultureString.includes("_")) {
      let cultureParts = cultureString.split(/[-_]/);
      cultureCode = cultureParts.length > 1 ? cultureParts[1] : cultureParts[0];
    } else if (cultureString.length === 2) {
      cultureCode = cultureString;
    }

    switch (cultureCode.toLowerCase()) {
      case "no":
      case "nb":
      case "nn":
        cultureCode = "nb";
        break;
      case "da":
      case "dk":
        cultureCode = "da";
        break;
      case "se":
        cultureCode = "sv";
      case "en":
        cultureCode = "en";
        break;
    }

    return cultureCode;
  }

  useEffect(() => {
    const fetchRegionalFooter = async () => {
      try {
        const response = await fetch(`/api/content/getRegionalFooter?country=${country}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          const responseText = await response.text();
          clientLogError("ClientFooter.tsx", "fetch get regional footer", `Network response was not ok: ${responseText}`);
        }

        const regionalFooter = await response.json() as iRegionalFooter;
        setRegionalFooter(regionalFooter);
      }
      catch (error) {
        clientLogError("error.tsx", "fetchSimplifiedRequestContext", error);
      }
    };

    fetchRegionalFooter();
  }, []);

  useEffect(() => {
    const initData = async () => {
      const languageCodeTemp = getISOCultureCode(language);
      setLanguageCode(languageCodeTemp);

      const currentLanguageColumnsTemp = regionalFooter?.content[languageCodeTemp]?.columns || [];
      setCurrentLanguageColumns(currentLanguageColumnsTemp);

      const contentTemp = regionalFooter?.content[languageCodeTemp];
      setContent(contentTemp);

      const splitArrayIntoColumns = (array: iAreaSimpelModel[], numColumns: number) => {
        const columns: iAreaSimpelModel[][] = Array.from({ length: numColumns }, () => []);
        array.forEach((item, index) => {
          columns[index % numColumns].push(item);
        });
        return columns;
      }

      const hasFacilities = (area: iAreaSimpelModel): boolean => {
        if (area.facilities && area.facilities.length > 0) {
          return true;
        }
        if (area.subAreas && area.subAreas.length > 0) {
          return area.subAreas.some(subArea => hasFacilities(subArea));
        }
        return false;
      };



      const regionAreas = getAreasByType(areas, eAreaType.Region)
        .sort((a, b) => a.sortOrder - b.sortOrder);

      const filteredRegionAreas = regionAreas.filter(area => hasFacilities(area));

      const columns = splitArrayIntoColumns(filteredRegionAreas, 4);
      setColumns(columns);

      const otherLanguageTemp = existingLanguages?.find(lang => lang.key !== languageCode);
      setOtherLanguage(otherLanguageTemp);
      setIsLoading(false);
    };

    initData();
  }, [regionalFooter]);

  useEffect(() => {
    if (regionalFooter && languageCode && currentLanguageColumns && content && columns && otherLanguage) {
      setIsLoading(false);
    }
  }, [regionalFooter, languageCode, currentLanguageColumns, content, columns, otherLanguage]);

  return (
    <footer className="footer bg-gs-green-400 text-gs-white py-10 px-6 lg:p-20">
      <div className="container mx-auto">
        <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
        {!isLoading && regionalFooter && content && (
          <>
            <div className="section pb-14">
              <div className="image-container text-left">
                <Image
                  src="/images/gs_logo_white.svg"
                  width={216}
                  height={32}
                  alt="Green Storage"
                  priority
                  className="h-[40px] lg:h-[80px] block"
                  style={{ width: 'auto', height: 'auto' }} // Maintain aspect ratio
                />
              </div>
            </div>
            <>
              {/* Our Facilities Desktop */}
              <div className="section py-14 text-sm hidden lg:block">
                <div className=" mb-4">
                  <span className={`${styles.columnHeader}`}>{translations.general.ourFacilities}</span>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-12">
                  {columns && columns.map((column, columnIndex) => (
                    <div key={columnIndex} className="flex flex-col gap-4">
                      {column.map((area, areaIndex) => (
                        <div key={`${columnIndex}_${areaIndex}`} className="">
                          <FooterAreaAccordion key={area.id} area={area} translations={translations} isFirst={true} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              {/* Our Facilities Mobile */}
              <div className="section py-6 text-sm lg:hidden">
                <Accordion
                  title={translations.general.ourFacilities}
                  translations={translations}
                  titleElement={(<h3>{translations.general.ourFacilities}</h3>)}
                  className={styles.mobileAccordion}
                  useWhiteIcon={true}
                  useChevrons={false}
                >
                  <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:gap-12">
                    {columns && columns.map((column, columnIndex) => (
                      <div key={columnIndex} className="flex flex-col gap-4">
                        {column.map((area, areaIndex) => (
                          <div key={`${columnIndex}_${areaIndex}`} className="">
                            <FooterAreaAccordion key={area.id} area={area} translations={translations} isFirst={true} useChildPadding={true} />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </Accordion>
              </div>
            </>
            <>
              {/* Link Columns Desktop */}
              <div className="section py-14 text-sm hidden lg:grid grid-cols-1 gap-12 lg:grid-cols-4 ">
                {currentLanguageColumns.map((column, columnIndex) => (
                  <div key={columnIndex} className="text-sm grid grid-cols-1 gap-4 auto-rows-min">
                    <div>
                      <span className={`${styles.columnHeader} inline-block`}>{column.title}</span>
                    </div>
                    {column.links.map((link: any, linkIndex: number) => {
                      if (!link) {
                        logError('Footer.tsx', 'link is undefined', 'linkIndex', linkIndex);
                      }

                      return (
                        <div key={linkIndex}>
                          <a href={link.url} title={`${translations.general.goTo} ${link.linkText}`} className={`${styles.link}`}>{link.linkText}</a>
                        </div>
                      )
                    })}
                  </div>
                ))}
                <div className="h-[205px]">
                  <Image
                    src="/images/logo_slogan.svg"
                    width={200}
                    height={205}
                    alt="Remove Restore Reuse"
                    className="float-right block"
                  />
                </div>
              </div>
              {/* Link Columns Mobile */}
              <div className="text-sm grid grid-cols-1 lg:hidden">
                {currentLanguageColumns.map((column, columnIndex) => (
                  <Accordion
                    key={columnIndex}
                    title={column.title}
                    translations={translations}
                    titleElement={(<h3>{column.title}</h3>)}
                    className={`${styles.mobileAccordion} section py-6`} useWhiteIcon={true}
                    useChevrons={false}
                  >
                    {column.links.map((link: any, linkIndex: number) => (
                      <div key={linkIndex}>
                        <a href={link.url} title={`${translations.general.goTo} ${link.linkText}`} className={`${styles.link}`}>{link.linkText}</a>
                      </div>
                    ))}
                  </Accordion>
                ))}
                <div className="h-[80px] lg:h-auto my-6 xl:p-0 lg:w-[200px] lg:h-[205px]">
                  <Image
                    src="/images/logo_slogan.svg"
                    width={200}
                    height={205}
                    alt="Remove Restore Reuse"
                    className="lg:float-right block h-[80px] w-[80px] lg:w-[200px] lg:h-[205px] lg:w-auto lg:h-auto "
                  />
                </div>
              </div>
            </>
            <div className="pt-6 flex flex-col lg:flex-row justify-between items-start lg:items-center">
              <div className={`copyrightContainer lg:flex-grow`}>
                <span className="font-bold block lg:inline w-full lg:w-auto pb-2 lg:pb-0">© {new Date().getFullYear()} {regionalFooter.copyrightText}</span>
                <span className="hidden lg:inline"> · </span>
                <span>
                  <a href={content.privacyPolicyLink.url} title={content.privacyPolicyText} className={`${styles.link}`}>{content.privacyPolicyText}</a>
                </span>
                <span> · </span>
                <span>
                  <a href={content.cookiePolicyLink.url} title={content.cookiePolicyText} className={`${styles.link}`}>{content.cookiePolicyText}</a>
                </span>
                {otherLanguage && (
                  <>
                    <span> · </span>
                    <span>
                      <a href={otherLanguage.path} title={translations.general.inOppositeLanguage} className={`${styles.link}`}>{translations.general.inOppositeLanguage}</a>
                    </span>
                  </>
                )}
                <span> · </span>
                <span>
                  <a href="/" title={translations.general.globalStartPage} className={`${styles.link}`}>{translations.general.globalStartPage}</a>
                </span>
              </div>

              <div className={`flex lg:justify-end items-center  space-x-5 mt-6 lg:mt-0`}>
                {regionalFooter?.acceptedPaymentCardsImageUrls?.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl}
                    className={`inline-block max-h-[24px]`}
                  />
                ))}
                <SocialLinks
                  translations={translations}
                  facebookUrl={regionalFooter?.linkFacebook}
                  instagramUrl={regionalFooter?.linkInstagram}
                  linkedInUrl={regionalFooter?.linkLinkedIn}
                  twitterUrl={regionalFooter?.linkTwitter}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </footer>
  );
}

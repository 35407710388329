"use client";

import React, { useEffect, useRef, useState } from "react";
import { iFacilitySimpelModel } from "@/app/models/apiModels/facilitySimpel";
import styles from "./checkouttextcontainer.module.scss";
import LinkElement from "../General/LinkElement";
import { eButtonColorTheme } from "@/app/models/enum/eButtonColorTheme";
import { eChevronPosition } from "@/app/models/enum/chevronPosition";

interface OrderStoragePageProps {
  facility: iFacilitySimpelModel | undefined | null;
  translations: any;
}

export default function CheckoutTextContainer({ facility, translations }: OrderStoragePageProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHasMounted(true);
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 112); // 112px is the height equivalent to 7rem or "max-h-28"
    }
  }, [facility]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {(facility && facility.checkoutText?.markup) && (
        <div className="w-full bg-gs-light-blue rounded-lg p-6 mb-6 relative">
          <div
            ref={contentRef}
            className={`richtext ${isExpanded ? 'max-h-none' : 'max-h-28 overflow-hidden relative'}`}
            dangerouslySetInnerHTML={{ __html: facility.checkoutText.markup }}
          ></div>
          {hasMounted && !isExpanded && isOverflowing && (
            <div className={`${styles.fadeBottom}`}></div>
          )}
          {hasMounted && isOverflowing && (
            <LinkElement
              title={isExpanded ? translations.general.showLess : translations.general.showMore}
              chevronPosition={eChevronPosition.None}
              onClick={toggleExpand}
            >
              {isExpanded ? translations.general.showLess : translations.general.showMore}
            </LinkElement>
          )}
        </div>
      )}
    </>
  );
}

'use client';

import { iMyPagesUserOrgData } from '@/app/models/myPages/myPagesUserOrgData';
import { iMyPagesUserPersonData } from '@/app/models/myPages/myPagesUserPersonData';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../Forms/FormInput';
import Modal from '../Modal/Modal';
import GDPR from '../GDPR/GDPR';
import Loader from '../Loader/Loader';
import { iRequestContext } from '@/app/models/contextModels/requestContext';
import LinkElement from '../General/LinkElement';
import { eChevronColor } from '@/app/models/enum/chevronColor';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { eLinkSize } from '@/app/models/enum/linkSize';
import { eTextColor } from '@/app/models/enum/textColor';
import { iMyPagesUser } from '@/app/models/myPages/myPagesUser';
import { clientLogError, clientLogInfo } from '@/app/helpers/clientLoggingHelper';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';
import styles from "./mypages.module.scss";
import { GetContentListAsync } from '@/services/umbraco/umbraco.content';
import { iFAQBlock } from '@/app/models/blockTypes/faqBlock';
import { iGetContentListRequestModel } from '@/app/models/apiModels/requestModels/getContentListRequestModel';
import FAQBlock from '@/app/content/blocks/FAQBlock/FAQBlock';


interface Props {
    profile: iMyPagesUser;
    country: string;
    changePaymentUrl: string;
    translations: any;
    payByInvoice: boolean;
    acceptedPaymentCardsImageUrls: string[] | undefined;
    language: string;
    previewMode: boolean;
    faqBlockIdsList?: string[] | undefined;
    currentUrl: string;
    //   requestContext: iRequestContext;
}

interface iCardInformation {
    hiddenNumber: string;
    type: string;
    image: string;
    expireDate: string;
    expireWarning: boolean;
}

export default function CardInformation({ profile, country, changePaymentUrl, translations, payByInvoice, currentUrl, acceptedPaymentCardsImageUrls, faqBlockIdsList, language, previewMode }: Props) {
    const [cardInfo, setCardInfo] = useState<iCardInformation | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFaqBlocksLoading, setFaqBlocksLoading] = useState<boolean>();
    const [fetchedFaqBlocks, setFetchedFaqBlock] = useState<iFAQBlock[]>();

    useEffect(() => {
        const getCurrentProfile = () => {
            return profile.userDataPerson ? profile.userDataPerson : profile.userDataOrganisation;
        }

        const currentProfile = getCurrentProfile();
        let cardTypeImage = "";

        if (currentProfile
            && currentProfile.creditCardType
            && currentProfile.hiddenCreditCardNumber
            && currentProfile.creditCardExpireDate
            && currentProfile.creditCardExpireWarning !== undefined) {

            switch (currentProfile.creditCardType.toLowerCase()) {
                case "mc":
                    cardTypeImage = "mc-logo.png";
                    break;
                case "amex":
                    cardTypeImage = "amex-logo.png";
                    break;
                case "maestro":
                    cardTypeImage = "maestro-logo.png";
                    break;
                case "visa":
                default:
                    cardTypeImage = "visa_logo.png";
                    break;
            }

            setCardInfo({
                hiddenNumber: currentProfile.hiddenCreditCardNumber,
                type: currentProfile.creditCardType,
                image: cardTypeImage,
                expireDate: currentProfile.creditCardExpireDate,
                expireWarning: currentProfile.creditCardExpireWarning
            });
        } else {
            setIsLoading(false);
        }
    }, [profile]);

    useEffect(() => {
        if (cardInfo) {
            setIsLoading(false);
        }
    }, [cardInfo]);

    const fetchFAQBlocks = async () => {
        clientLogInfo("CardInformation.tsx", "fetchFAQBlocks");
        setFaqBlocksLoading(true);

        const requestModel: iGetContentListRequestModel = {
            contentIds: faqBlockIdsList ?? [],
            language: language,
            previewMode: previewMode,
        };

        try {
            const response = await fetch('/api/content/getContentList', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log("Data", data);
            const faqBlocks = data as iFAQBlock[];
            console.log("Response data", faqBlocks);
            clientLogInfo("CardInformation.tsx", "fetchFAQBlocks", `Fetched items: ${faqBlocks.length} items`);
            setFetchedFaqBlock(faqBlocks);
        } catch (error) {
            clientLogError("BlogListing.tsx", "fetchItems", error);
        } finally {
            setFaqBlocksLoading(false);
        }
    };

    useEffect(() => {
        // const fetchFaqBlocks = async () => {
        //     try {
        //         const fetchedFaqBlocks = await GetContentListAsync(faqBlockIdsList ?? [], language, previewMode);
        //         setFetchedFaqBlock(fetchedFaqBlocks);
        //     } catch (error) {
        //         console.error("Error fetching FAQ blocks:", error);
        //     }
        // };

        // fetchFaqBlocks();
        console.log("Component load");
        fetchFAQBlocks();
    }, []);


    return (
        <div className="rounded-3xl bg-white p-10 mb-10">
            <div className="flex flex-wrap items-center justify-between">
                <h2 className="flex-shrink-0">
                    {translations.pages.changePaymentPage.currentPaymentCard}
                </h2>
                {/* {acceptedPaymentCardsImageUrls && (
                    <div className="flex flex-wrap">
                        {acceptedPaymentCardsImageUrls?.map((imageUrl, index) => (
                            <img
                                key={index}
                                src={imageUrl}
                                className={`inline-block max-h-[24px]`}
                            />
                        ))}
                    </div>
                )} */}
            </div>
            {isLoading ? (
                <Loader loading={isLoading} useFullWindow={false} useNoBackground={true} />
            ) : (
                <>
                    <div className="card-information flex items-center justify-between">
                        {!payByInvoice && (
                            <>
                                {cardInfo ? (
                                    <div className="flex items-center">
                                        <img src={`/assets/img/icons/${cardInfo.image}`} alt={cardInfo.type} className="mr-4" />
                                        <div className="text-normal font-semibold mb-0-i">
                                            {cardInfo.hiddenNumber} {cardInfo.expireDate}
                                        </div>
                                        {cardInfo.expireWarning && (
                                            <div className="ml-4 font-warning font-semibold">{translations.pages.changePaymentPage.expiresSoon}</div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        {translations.pages.changePaymentPage.noCardSaved}
                                    </div>
                                )}
                            </>
                        )}
                        <div>
                            {acceptedPaymentCardsImageUrls && (
                                <div className="relative">
                                    <div className={`${styles.acceptedCardsContainer} flex justify-center space-x-2`}>
                                        {acceptedPaymentCardsImageUrls?.map((imageUrl, index) => (
                                            <img
                                                key={index}
                                                src={imageUrl}
                                                className={`inline-block max-h-[24px]`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                            <LinkElement
                                title={cardInfo ? translations.pages.changePaymentPage.changePaymentCard : translations.pages.changePaymentPage.addCard}
                                buttonColorTheme={eButtonColorTheme.Green}
                                chevronPosition={eChevronPosition.None}
                                href={`${changePaymentUrl}?isCompany=${profile.userDataOrganisation ? true : false}&ui=${profile.userDataPerson ? profile.userDataPerson.customerId : profile.userDataOrganisation?.customerId}`}
                                isButton={true}
                            >
                                {cardInfo ? translations.pages.changePaymentPage.changePaymentCard : translations.pages.changePaymentPage.addCard}
                            </LinkElement>
                        </div>
                    </div>
                    {isFaqBlocksLoading ? (
                        <Loader loading={isFaqBlocksLoading} useFullWindow={false} useNoBackground={true} />
                    ) : (
                        <>
                            {fetchedFaqBlocks &&
                                <div className="grid grid-cols-1 gap-6 mt-3">
                                    {fetchedFaqBlocks.map((faqBlock: iFAQBlock) => (
                                        <FAQBlock key={faqBlock.id} block={faqBlock} translations={translations} currentUrl={currentUrl} isInGroup={true} />
                                    ))}
                                </div>
                            }
                        </>
                    )}
                </>
            )}
        </div>
    );
}

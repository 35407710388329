'use client'
import { eChevronPosition } from "@/app/models/enum/chevronPosition";
import LinkElement from "../General/LinkElement";
import styles from "./pagination.module.scss";
import { eLinkSize } from "@/app/models/enum/linkSize";

interface Props {
    currentPage: number;
    totalPages: number;
    showPrevious: boolean;
    showNext: boolean;
    onClick?: (page: number) => void;
    baseUrl?: string;
    previousText?: string;
    nextText?: string;
}


export default function Pagination({ currentPage, totalPages, showPrevious, showNext, onClick, baseUrl, previousText, nextText }: Props) {
    if (baseUrl?.endsWith("/")) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    const generatePageNumbers = (currentPage: number, totalPages: number) => {
        const delta = 4; // Number of pages to show around the current page
        const range = [];
        const rangeWithDots: any[] = [];
        let l: any;

        range.push(1);
        for (let i = currentPage - delta; i <= currentPage + delta; i++) {
            if (i >= 1 && i <= totalPages) {
                range.push(i);
            }
        }
        range.push(totalPages);

        range.forEach((i) => {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push("...");
                }
            }
            rangeWithDots.push(i);
            l = i;
        });

        // Remove the first page and ellipsis if it's within the delta range
        if (currentPage <= delta + 2) {
            rangeWithDots.splice(0, 2);
        }

        // Remove the last page if it's within the delta range
        if (currentPage >= totalPages - delta - 1) {
            rangeWithDots.splice(rangeWithDots.length - 2, 2);
        }

        return rangeWithDots;
    };

    const pageNumbers = generatePageNumbers(currentPage, totalPages);

    return (
        <div className={styles.paginationContainer} aria-label="Pagination Navigation">
            <div className={`${styles.pagination} justify-center flex gap-2 py-8`}>
                {showPrevious && (
                    <>
                        {currentPage > 1 ? (
                            <LinkElement
                                title={previousText ?? "Previous"}
                                className={styles.paginationButton}
                                chevronPosition={eChevronPosition.None}
                                disabled={currentPage === 1}
                                linkSize={eLinkSize.Medium}
                                href={`${baseUrl}/page/${currentPage - 1}/`}
                                onClickAndLink={true}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(currentPage - 1);
                                    }
                                }}
                                aria-label="Previous Page"
                            >
                                {previousText ?? "Previous"}
                            </LinkElement>
                        ) : (
                            <span className={styles.disabled} aria-disabled="true">
                                {previousText ?? "Previous"}
                            </span>
                        )}

                    </>
                )}
                {pageNumbers.map((page, index) =>
                    page === "..." ? (
                        <span key={index} className={styles.ellipsis} aria-hidden="true">...</span>
                    ) : (
                        <>
                            {page === currentPage ? (
                                <span className={styles.current} key={index} aria-current="page">
                                    {page}
                                </span>
                            ) : (
                                <LinkElement
                                    key={index}
                                    title={page.toString()}
                                    className={styles.paginationButton}
                                    chevronPosition={eChevronPosition.None}
                                    linkSize={eLinkSize.Medium}
                                    disabled={currentPage === page}
                                    href={`${baseUrl}/page/${page}/`}
                                    onClickAndLink={true}
                                    onClick={() => {
                                        if (onClick) {
                                            onClick(page);
                                        }
                                    }}
                                >
                                    {page}
                                </LinkElement >
                            )}
                        </>
                    )
                )}
                {showNext && (
                    <>
                        {currentPage < totalPages ? (
                            <LinkElement
                                title={nextText ?? "Next"}
                                className={styles.paginationButton}
                                chevronPosition={eChevronPosition.None}
                                linkSize={eLinkSize.Medium}
                                disabled={currentPage === 1}
                                href={`${baseUrl}/page/${currentPage + 1}/`}
                                onClickAndLink={true}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(currentPage + 1);
                                    }
                                }}
                            >
                                {nextText ?? "Next"}
                            </LinkElement>
                        ) : (
                            <span className={styles.disabled} aria-disabled="true">
                                {nextText ?? "Next"}
                            </span>
                        )}
                    </>
                )}
            </div>
        </div >
    );
}

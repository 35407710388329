'use client';
import { iDictionary } from '@/app/models/dictionary';
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import useStore from "@/stores/use-store";
import { useEffect, useState } from 'react';
import { useShallow } from "zustand/react/shallow";

interface Props {
    searchParams: iDictionary | undefined;
}


export default function RecruitRefIDToLocalStorage({ searchParams }: Props) {
    const setRecruitAFriendValue = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.setRecruitAFriendValue;

    const [referenceId, setReferenceId] = useState<string | null>(null);
   
    useEffect(() => {
        if (searchParams?.refId) {
            setReferenceId(searchParams?.refId as string);
        }
    }, []);

    useEffect(() => {
        if (referenceId && setRecruitAFriendValue) {
            setRecruitAFriendValue("referenceId", referenceId);
        }
    }, [referenceId, setRecruitAFriendValue]);

    return (
        <>
        </>
    );
}

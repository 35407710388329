"use client"
import React, { useState, useEffect, useRef } from "react";
import { eFetchSpecifier } from "@/app/models/enum/fetchSpecifier";
import { iUmbracoGroupResponse } from "@/app/models/apiModels/umbracoGroupResponse";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { iGetContentRequestModel } from "@/app/models/apiModels/requestModels/getContentRequestModel";
import { iCampaignPage } from "@/app/models/pageTypes/campaignPage";
import CampaignItem from "../CampaignItem/CampaignItem";
import { iFacilityPage } from "@/app/models/pageTypes/facilityPage";
import Loader from "../Loader/Loader";
import { GetAbsoluteUrlClient, StripHtml } from "@/app/helpers/clientUtilityHelper";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import Pagination from "../Pagination/Pagination";

interface Props {
    pageId: string;
    translations: any;
    itemsPerPage: number;
    defaultImage: string;
    country: string;
    language: string;
    initialPage: number;
}

export default function CampaignListing({ pageId, translations, itemsPerPage, defaultImage, country, language, initialPage }: Props) {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [items, setItems] = useState<iCampaignPage[]>([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [baseUrl, setBaseUrl] = useState("");

    useEffect(() => {
        const currentUrl = window.location.pathname;
        const pageMatch = currentUrl.match(/\/page\/(\d+)\/?$/);
        let newUrl;
        if (pageMatch) {
            newUrl = currentUrl.replace(/\/page\/(\d+)\/?$/, "");
        } else {
            newUrl = `${currentUrl}`;
        }
        setBaseUrl(newUrl);
    }, []);

    useEffect(() => {
        fetchItemsForPage(currentPage);
    }, [currentPage]);

    const fetchItemsForPage = async (page: number) => {
        await fetchItems((page - 1) * itemsPerPage, itemsPerPage);
    };

    const fetchItems = async (skip: number, take: number) => {
        setLoading(true);

        const formattedDate = (new Date()).toISOString();

        const requestModel: iGetContentRequestModel = {
            pageId: pageId,
            childrenAncestorOrDescendantsSpecifier: eFetchSpecifier.Descendants,
            previewMode: false,
            skip: skip,
            take: take,
            extraQuery: `filter=campaignDate:${formattedDate}&sort=campaignEndDate:asc`,
            language: language,
            contentType: "campaignPage",
        };

        try {
            const response = await fetch('/api/content/getContent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const umbracoResponsemodel = data.content as iUmbracoGroupResponse;
            const newItems = (umbracoResponsemodel.items ?? []) as iCampaignPage[];

            // Filter out items that are already in the state
            const filteredNewItems = newItems.filter(newItem => !items.some(item => item.id === newItem.id));

            setTotalPages(Math.ceil(umbracoResponsemodel.total / itemsPerPage));
            setItems((prevItems) => [...prevItems, ...filteredNewItems]);
        } catch (error) {
            clientLogError("CampaignListing.tsx", "fetchItems", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6 xl:p-0">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {items && items.map((child: iCampaignPage, index: number) => {
                    return (
                        <CampaignItem
                            key={index}
                            href={child.route.path}
                            title={child.properties.title}
                            image={child.properties.image ? GetAbsoluteUrlClient(child.properties.image[0].url) : defaultImage}
                            text={StripHtml(child.properties.preamble ?? child.properties.mainBody?.markup)}
                            campaignEndDate={child.properties.campaignEnd}
                            campaignStartDate={child.properties.campaignStart}
                            campaignLabel={child.properties.campaignLabel}
                            campaignId={child.id}
                            country={country}
                            siteIdList={child.properties.linkedFacilitiesAreas.filter(x => x.contentType === "facilityPage").map(x => (x as iFacilityPage).properties.facilityID)}
                            translations={translations}
                        />
                    )
                })}
            </div>
            {loading ? (
                <div className="flex justify-center mt-10 relative">
                    <Loader loading={loading} useFullWindow={false} useNoBackground={true} />
                </div>
            ) : (
                <Pagination currentPage={currentPage} totalPages={totalPages} showPrevious={true} showNext={true} baseUrl={baseUrl} nextText={translations.general.next} previousText={translations.general.previous} />
            )}
        </div>
    );
};

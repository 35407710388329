import React from "react";
import { iLinkContent } from "@/app/models/iLinkContent";
import styles from "./blogpostitem.module.scss";
import LinkElement from "../General/LinkElement";
import { eTarget } from "@/app/models/enum/target";

export interface Props {
    // key?: React.Key;
    title?: string;
    text?: string;
    image: string;
    className?: string;
    href: string;
    postedDate: string;
}

export default function BlogPostItem({ title, text, image, href, className, postedDate }: Props) {
    //bg-light-gs-green p-10 rounded-3xl

    return (
        <a href={href} className={`${styles.cardItem} relative rounded-3xl bg-light-gs-green ${className}`}>
            <div>
                <div className="relative h-[200px]">
                    <img
                        src={image}
                        alt={title}
                        className={`rounded-t-3xl ${styles.image}`}
                    />
                </div>
                <div className="p-10 pb-6 flex flex-col h-full">
                    <h3>{title}</h3>
                    <div className="text-normal mb-0-i truncate-multiline-4">
                        {text}
                    </div>
                    {postedDate && (
                    <div className="description text-right mt-4 mb-0-i">
                        {postedDate}
                    </div>
                    )}
                </div>
            </div>
        </a>
    );
}

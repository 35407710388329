'use client'
import { iMyPagesPage } from "@/app/models/pageTypes/myPagesPage";
import styles from './mypages.module.scss';
import RichText from "@/app/components/RichText/RichText";
import MyPagesComponentContainer from "@/app/components/MyPagesComponentContainer/MyPagesComponentContainer";
import { iMyPagesUser } from "@/app/models/myPages/myPagesUser";
import { useState } from "react";
import ProfileAndLogout from "../ProfileAndLogout/ProfileAndLogout";
import { clientLogInfo } from '@/app/helpers/clientLoggingHelper';
import { iRecruitModel } from "@/app/models/recruitModel";

interface MyPagesProps {
  pageData: iMyPagesPage;
  profiles: iMyPagesUser[];
  mainBlockArea?: React.ReactNode;
  secondaryBlockArea?: React.ReactNode;
  faqBlockIdsList?: string[] | undefined;
}

const MyPages: React.FC<MyPagesProps> = ({ pageData, profiles, mainBlockArea, secondaryBlockArea, faqBlockIdsList }: MyPagesProps) => {
  const lastSelectedCustomerId = localStorage.getItem('lastSelectedCustomerId');
  let initialProfile: iMyPagesUser | null = null;
  if (lastSelectedCustomerId) {
    initialProfile = profiles.find(profile =>
      (profile.userDataPerson && profile.userDataPerson.customerId === lastSelectedCustomerId) ||
      (profile.userDataOrganisation && profile.userDataOrganisation.customerId === lastSelectedCustomerId)
    ) || (profiles.length > 0 ? profiles[0] : null);
  } else {
    initialProfile = profiles.length > 0 ? profiles[0] : null;
  }

  const [selectedProfile, setSelectedProfile] = useState<iMyPagesUser | null>(initialProfile);
  const recruitCustomerId = initialProfile?.userDataPerson?.customerId ?? initialProfile?.userDataOrganisation?.customerId;
  const recruitUserForename = initialProfile?.userDataPerson?.forename ?? initialProfile?.userDataOrganisation?.forename;
  const recruitUserSurname = initialProfile?.userDataPerson?.surname ?? initialProfile?.userDataOrganisation?.surname;
  const baseUrl = `${pageData.requestContext.mainUrl}/${pageData.requestContext.country}/`;
  const recruitModel: iRecruitModel = {
    recruitReferenceId: recruitCustomerId,
    userForename: recruitUserForename,
    userSurname: recruitUserSurname,
    baseUrl: baseUrl,
    buttonText: pageData.properties.buttonText,
    modalTitle: pageData.properties.modalTitle,
    modalFirstText: pageData.properties.modalFirstText,
    modalLinkText: pageData.properties.modalLinkText,
    modalSecondText: pageData.properties.modalSecondText,
    emailSubject: pageData.properties.emailSubject,
    emailBody: pageData.properties.emailBody
  };

  const handleProfileChange = (profile: iMyPagesUser) => {
    setSelectedProfile(profile);
    let customerId;
    if (profile.userDataPerson) {
      customerId = profile.userDataPerson.customerId;
    } else if (profile.userDataOrganisation) {
      customerId = profile.userDataOrganisation.customerId;
    }

    if (customerId) {
      localStorage.setItem('lastSelectedCustomerId', customerId);
    }
  };

  const handleUpdateProfile = (updatedProfile: iMyPagesUser) => {
    if (updatedProfile.userDataOrganisation) {
      profiles.forEach(profile => {
        if (profile.userDataOrganisation && profile.userDataOrganisation.customerId === updatedProfile.userDataOrganisation?.customerId) {
          profile.userDataOrganisation.forename = updatedProfile.userDataOrganisation.forename;
          profile.userDataOrganisation.surname = updatedProfile.userDataOrganisation.surname;
          profile.userDataOrganisation.streetAddress = updatedProfile.userDataOrganisation.streetAddress;
          profile.userDataOrganisation.zipCode = updatedProfile.userDataOrganisation.zipCode;
          profile.userDataOrganisation.city = updatedProfile.userDataOrganisation.city;
          profile.userDataOrganisation.email = updatedProfile.userDataOrganisation.email;
          profile.userDataOrganisation.phoneNumber = updatedProfile.userDataOrganisation.phoneNumber;
          profile.userDataOrganisation.invoiceEmail = updatedProfile.userDataOrganisation.invoiceEmail;
          profile.userDataOrganisation.invoiceReference = updatedProfile.userDataOrganisation.invoiceReference;
        }
      });

      setSelectedProfile(updatedProfile);
    } else if (updatedProfile.userDataPerson) {
      profiles.forEach(profile => {
        if (profile.userDataPerson && profile.userDataPerson.customerId === updatedProfile.userDataPerson?.customerId) {
          profile.userDataPerson.forename = updatedProfile.userDataPerson.forename;
          profile.userDataPerson.surname = updatedProfile.userDataPerson.surname;
          profile.userDataPerson.streetAddress = updatedProfile.userDataPerson.streetAddress;
          profile.userDataPerson.zipCode = updatedProfile.userDataPerson.zipCode;
          profile.userDataPerson.city = updatedProfile.userDataPerson.city;
          profile.userDataPerson.email = updatedProfile.userDataPerson.email;
          profile.userDataPerson.phoneNumber = updatedProfile.userDataPerson.phoneNumber;
        }
      });

      setSelectedProfile(updatedProfile);
    }
  };

  return (
    <>
      <div className="flex flex-col-reverse flex-col lg:flex-row lg:justify-between items-start lg:items-center lg:mb-10">
        <h1 className={`${styles.titleWithIcon} mb-4 lg:mb-0`}>{pageData.properties.title}</h1>
        <ProfileAndLogout
          userProfiles={profiles}
          translations={pageData.requestContext.translations}
          onProfileChange={handleProfileChange}
          logoutRedirectUrl={pageData.requestContext.configurations.pages.logoutRedirect.url}
          country={pageData.requestContext.country}
          recruitModel={recruitModel}
        />
      </div>
      {secondaryBlockArea && (
        <>
          {secondaryBlockArea}
        </>
      )}

      {pageData.properties.mainBody && (
        <div className="rounded-3xl bg-white p-10 mb-10">
          <RichText text={pageData.properties.mainBody} />
        </div>
      )}

      {selectedProfile && (
        <MyPagesComponentContainer 
        selectedProfile={selectedProfile} 
        requestContext={pageData.requestContext} 
        onUpdateProfile={handleUpdateProfile}
        faqBlockIdsList={faqBlockIdsList} />
      )}

      {mainBlockArea && (
        <>
          {mainBlockArea}
        </>
      )}
    </>
  );
};

export default MyPages;
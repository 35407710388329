import { iAreaSimpelModel } from "../models/apiModels/areaSimpel";
import { iBaseBackgroundBlock } from "../models/blockTypes/baseBackgroundBlock";
import { iBaseBlock, iBaseBlockProperties } from "../models/blockTypes/baseBlock";
import { iFlattenedArea } from "../models/componentModels/flattenedArea";
import { iCoordinates } from "../models/coordinates";
import { eAreaType } from "../models/enum/areaType";
import { clientLogError, clientLogInfo } from "./clientLoggingHelper";

//SHOULD ONLY HAVE CODE THAT IS SHARED BETWEEN CLIENT AND SERVER, NO SERVER SPECIFIC CALLS!
export {
    getAbsoluteUrlClient as GetAbsoluteUrlClient,
    getDistance as GetDistance,
    removeTrailingSlashFromUrl as RemoveTrailingSlashFromUrl,
    getFormattedDate as GetFormattedDate,
    stripHtml as StripHtml,
    flattenAreaHierarchy as FlattenAreaHierarchy,
    flattenRegionAreaHierarchy as FlattenRegionAreaHierarchy,
    searchFlattenedAreaHierarchy as SearchFlattenedAreaHierarchy,
    getBackgroundColorClass as GetBackgroundColorClass,
    getBlockWrapperClasses as GetBlockWrapperClasses,
    generateUUID as GenerateUUID,
    hashString as HashString
};

function getAbsoluteUrlClient(url: string, baseUmbracoUrl?: string): string {
    clientLogInfo('clientUtilityHelper.ts', 'getAbsoluteUrlClient', 'url', url, 'baseUmbracoUrl', baseUmbracoUrl);
    try {
        if (!url || url.startsWith("http")) {
            return url;
        }

        if (baseUmbracoUrl) {
            return `${baseUmbracoUrl}${url}`;
        }

        return `${process.env.NEXT_PUBLIC_AZURE_MEDIA_CDN}${url}`;
    }
    catch (error) {
        clientLogError('clientUtilityHelper.ts', 'getAbsoluteUrlClient', 'url', url, 'baseUmbracoUrl', baseUmbracoUrl, 'Error', error);
        return "";
    }
}

async function hashString(input: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

function getDistance(coordinates1: iCoordinates, coordinates2: iCoordinates): number {
    const toRad = (x: number) => x * Math.PI / 180;
    const R = 6371; // Radius of the Earth in km
    const dLat = toRad(coordinates2.latitude - coordinates1.latitude);
    const dLon = toRad(coordinates2.longitude - coordinates1.longitude);
    const lat1 = toRad(coordinates1.latitude);
    const lat2 = toRad(coordinates2.latitude);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

function removeTrailingSlashFromUrl(url: string): string {
    return url.replace(/\/$/, "");
}

function getFormattedDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function stripHtml(html: string): string {
    if (!html) {
        return "";
    }
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}

function flattenAreaHierarchy(areas: iAreaSimpelModel[]): iFlattenedArea[] {
    const result: iFlattenedArea[] = [];

    const traverse = (area: iAreaSimpelModel, currentCityArea: iAreaSimpelModel | null) => {
        let cityArea = currentCityArea;

        if (area.areaType === eAreaType.City) {
            cityArea = area;
            result.push({ area: cityArea, facilities: [] });
        }

        if (cityArea) {
            const cityEntry = result.find(entry => entry.area.id === cityArea.id);
            if (cityEntry) {
                cityEntry.facilities.push(...area.facilities);
            }
        }

        area.subAreas.forEach(subArea => {
            traverse(subArea, cityArea);
        });
    };


    areas.forEach(area => traverse(area, null));
    return result;
}

function flattenRegionAreaHierarchy(areas: iAreaSimpelModel[]): iFlattenedArea[] {
    const result: iFlattenedArea[] = [];

    const traverse = (area: iAreaSimpelModel, currentRegionArea: iAreaSimpelModel | null) => {
        let regionArea = currentRegionArea;

        if (area.areaType === eAreaType.Region) {
            regionArea = area;
            result.push({ area: regionArea, facilities: [] });
        }

        if (regionArea) {
            const regionEntry = result.find(entry => entry.area.id === regionArea.id);
            if (regionEntry) {
                regionEntry.facilities.push(...area.facilities);
            }
        }

        area.subAreas.forEach(subArea => {
            traverse(subArea, regionArea);
        });
    };


    areas.forEach(area => traverse(area, null));
    return result;
}

function searchFlattenedAreaHierarchy(query: string, flattenedAreas: iFlattenedArea[]): iFlattenedArea[] {
    const lowerCaseQuery = query.toLowerCase();

    return flattenedAreas.map(({ area, facilities }) => {
        const areaMatches = area.name.toLowerCase().includes(lowerCaseQuery);

        if (areaMatches) {
            return { area, facilities };
        }

        const matchingFacilities = facilities.filter(facility => {
            const facilityMatches = facility.name.toLowerCase().includes(lowerCaseQuery) ||
                facility.streetAddress.toLowerCase().includes(lowerCaseQuery) ||
                facility.city.toLowerCase().includes(lowerCaseQuery);

            return facilityMatches;
        });

        if (matchingFacilities.length > 0) {
            return { area, facilities: matchingFacilities };
        }

        return null;

    }).filter(result => result !== null);
}

function getBackgroundColorClass(model?: iBaseBackgroundBlock) {
    if (!model) {
        return 'bg-see-through';
    }

    const backgroundColor = model.properties.backgroundColor as { label: string, color: string };
    const backgroundColorAlias = backgroundColor ? backgroundColor.label.toString().toLowerCase().replace(/ /g, "-") : 'see-through';

    switch (backgroundColorAlias) {
        case 'light-greenstorage-green':
            return 'bg-light-gs-green';
        case 'medium-greenstorage-green':
            return 'bg-medium-gs-green';
        case 'greenstorage-green':
            return 'bg-gs-green';
        case 'white':
            return 'bg-white';
        case 'see-through':
        default:
            return 'bg-see-through';
    }
}

function getBlockWrapperClasses(block: iBaseBlock, onlyRoundedInDesktop: boolean = false, hidePadding: boolean = false): string {
    let cssClasses = "block relative";

    if (isBaseBackgroundBlock(block)) {
        cssClasses += ` ${getBackgroundColorClass(block)}`;

        if (block.properties.showRoundedCorners && cssClasses.indexOf("bg-see-through") === -1) {
            cssClasses += ` ${onlyRoundedInDesktop ? "xl:rounded-3xl" : "rounded-3xl"} ${hidePadding ? "" : "p-6 lg:p-10"}`;
        } else if (cssClasses.indexOf("bg-see-through") > -1) {
            cssClasses += ` ${hidePadding ? "" : "px-6 xl:px-0"}`;
        }
    }
    else if (isBaseBlock(block)) {
        if (block.properties.showRoundedCorners) {
            cssClasses += ` ${onlyRoundedInDesktop ? "xl:rounded-3xl" : "rounded-3xl"} ${hidePadding ? "" : "p-6 lg:p-10"}`;
        }
    }

    if (hasShowBackgroundProperty(block)) {
        const modelWithShowBackground = block as unknown as iBaseBlockWithShowBackground;
        if (!modelWithShowBackground.properties.showBackground) {
            cssClasses += " hide-background";
        }
    }

    return cssClasses.trim();
}

function isBaseBackgroundBlock(model: iBaseBlock | iBaseBackgroundBlock): model is iBaseBackgroundBlock {
    return (model as iBaseBackgroundBlock).properties?.backgroundColor !== undefined;
}


function isBaseBlock(model: iBaseBlock | iBaseBackgroundBlock): model is iBaseBlock {
    return (model as iBaseBackgroundBlock).properties?.backgroundColor === undefined
        && (model as iBaseBlock).properties?.showRoundedCorners !== undefined;
}

function hasShowBackgroundProperty(model: any): model is { showBackground: boolean } {
    return model.properties?.showBackground !== undefined;
}

interface iBaseBlockWithShowBackground extends iBaseBlock {
    properties: iBaseBlockProperties & {
        showBackground: boolean;
    };
}

function generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
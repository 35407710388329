"use client"
import React, { useCallback, useEffect, useRef, useState } from "react";
import Image from 'next/image';
import styles from './footerareaaccordion.module.scss';
import { iAreaSimpelModel } from '@/app/models/apiModels/areaSimpel';
import FooterFacilityItem from '../FooterFacilityItem/FooterFacilityItem';
import { hasFacilities } from "@/app/helpers/areaHelper";

interface Props {
    area: iAreaSimpelModel;
    translations: any;
    isFirst: boolean;
    useChildPadding?: boolean;
}

export default function FooterAreaAccordion({ area, translations, isFirst, useChildPadding = false }: Props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentId = `accordion-content-${area.id}`;

    if (!hasFacilities(area)) {
        return null;
    }

    const childContent = (
        <>
            {area.subAreas && area.subAreas.map((subArea, index) => (
                <FooterAreaAccordion key={index} area={subArea} translations={translations} isFirst={false} useChildPadding={useChildPadding} />
            ))}
            {area.facilities && area.facilities.map((facility, index) => (
                <FooterFacilityItem key={index} facility={facility} translations={translations} />
            ))}
        </>
    );

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <div className={`${styles.footerAccordion}`}>
            <div className="flex justify-between items-center">
                <a
                    href={area.path}
                    title={`${translations.general.goTo} ${translations.general.theArea} ${area.name}`}
                    aria-label={`${translations.general.goTo} ${translations.general.theArea} ${area.name}`}
                    className={`flex-grow text-left mr-4 ${styles.link}`}>
                    {area.name}
                </a>

                <Image
                    src={isExpanded ? "/assets/img/icons/icon_minus_white.svg" : "/assets/img/icons/icon_plus_white.svg"}
                    width={16}
                    height={16}
                    alt={isExpanded ? `${translations.general.collapse} ${translations.general.theArea} ${area.name}` : `${translations.general.expand} ${translations.general.theArea} ${area.name}`}
                    className={`btn ${styles.accordionExpandIcon} cursor-pointer`}
                    role="button"
                    aria-expanded={isExpanded}
                    aria-controls={`accordion-content-${area.id}`}
                    onClick={toggleExpand}
                />
            </div>
            <div
                id={contentId}
                className={`${styles.contentContainer} ${isExpanded ? styles.expanded : ''}  lg:pl-4 ${useChildPadding ? "pl-4" : ""}`}
            >
                <div className="pt-4 flex flex-col gap-4">
                    {childContent}
                </div>
            </div>
        </div>
    );
}



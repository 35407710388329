"use client";

import { iOrderStoragePage } from "@/app/models/pageTypes/orderStorageStepPage";
import { useRouter } from "next/navigation";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { useShallow } from "zustand/react/shallow";
import { useEffect, useState } from "react";
import { iPayment, Payment, PaymentRow } from "@/app/models/apiModels/payment";
import React from "react";
import { clientLogError } from "@/app/helpers/clientLoggingHelper";
import styles from "./paymentcontainer.module.scss";
import { PaymentDetails } from "./PaymentDetails";
import Loader from "../Loader/Loader";
import useStore from "@/stores/use-store";
import { PaymentButton } from "./PaymentButton";
import { formatDate } from "@/app/helpers/dateHelper";
import CheckoutTextContainer from "./CheckoutTextContainer";
interface OrderStoragePageProps {
  pageData: iOrderStoragePage;
}

export default function PaymentContainer({ pageData }: OrderStoragePageProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [unitPriceResponse, setUnitPriceResponse] = useState<iPayment>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const translation = pageData.requestContext.translations;
  const contentType = pageData.contentType;
  const orderFlowUrls = pageData.requestContext.configurations.orderingFlow;
  const siteId = pageData.searchParams.siteId?.toString();
  const sizeCodeId = pageData.searchParams.sizeCodeId?.toString();
  let unitId = pageData.searchParams.unitId?.toString();

  const { unit, date, insurance, promotion, user, validation, payment } =
    doUseOrderStorageStore(
      useShallow((state) => ({
        unit: state.unit,
        date: state.date,
        insurance: state.insurance,
        promotion: state.promotion,
        user: state.user,
        validation: state.validation,
        payment: state.payment,
      }))
    );

  const setPaymentValue = useStore(
    doUseOrderStorageStore,
    useShallow((state) => state)
  )?.setPaymentValue;

  const fetchCalculatedUnit = async () => {
    try {
      //This is a fix, as when sending in the date to the API, it is sent in UTC, but the API expects it in local time. Otherwise it changes the date to the day before. (when the time is 00:00-01:00)
      const correctedDate = new Date(date.startDate);
      const pad = (num: number) => (num < 10 ? '0' + num : num);
      const timezoneOffset = -correctedDate.getTimezoneOffset();
      const sign = timezoneOffset >= 0 ? '+' : '-';
      const absOffset = Math.abs(timezoneOffset);
      const hoursOffset = pad(Math.floor(absOffset / 60));
      const minutesOffset = pad(absOffset % 60);
      const localDateString = `${correctedDate.getFullYear()}-${pad(correctedDate.getMonth() + 1)}-${pad(correctedDate.getDate())}T${pad(correctedDate.getHours())}:${pad(correctedDate.getMinutes())}:${pad(correctedDate.getSeconds())}.${correctedDate.getMilliseconds().toString().padStart(3, '0')}${sign}${hoursOffset}:${minutesOffset}`;

      const response = await fetch("/api/checkout/calculateUnit", {
        method: "POST",
        headers: {
          Country: pageData.requestContext.country,
        },
        body: JSON.stringify({
          unitId: unit.unitId,
          startDate: localDateString,
          insuranceId: insurance.insuranceLedgerId,
          isCompany: user.isCompany,
          promotionCode: promotion.promotionCode,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const unitPriceResponse = (await response.json()) as iPayment;
      setUnitPriceResponse(unitPriceResponse);

      if (setPaymentValue && unitPriceResponse) {
        setPaymentValue(
          "amount",
          unitPriceResponse.firstPayment.rows.slice(-1)[0].total.toString()
        );
        setPaymentValue(
          "vatAmount",
          unitPriceResponse.firstPayment.rows[0].vat.toString()
        );
      }

      setIsLoading(false);
    } catch (error) {
      clientLogError("PaymentContainer.tsx", "fetchCalculatedUnit", error);
    }
  };

  useEffect(() => {
    if (
      unit.unitId &&
      (insurance.insuranceLedgerId || user.isCompany || date.startDate)
    ) {
      setIsLoading(true);
      fetchCalculatedUnit();
    }
  }, [
    unit.unitId,
    insurance.insuranceLedgerId,
    user.isCompany,
    date.startDate,
  ]);

  return (
    <div className="w-full rounded-3xl bg-white p-6 lg:p-10">
      <Loader loading={isLoading} useFullWindow={true} />
      <h3 className="text-md font-bold">
        {translation.pages.order?.paymentContainer.yourPrice ?? "Your Price"}
      </h3>
      <CheckoutTextContainer facility={pageData.properties.facility} translations={translation} />
      <div className="inline-flex font-bold w-full justify-between">
        <h4>{translation.pages.order?.paymentContainer.toPayNow}</h4>
        <h4>
          {unitPriceResponse &&
            `${parseFloat(
              unitPriceResponse.firstPayment.rows.slice(-1)[0].total.toString()
            ).toFixed(2)} ${translation.general.priceUnit}`}
        </h4>
      </div>
      <div className="border-b border-gs-grey">
        <p
          className="underline underline-offset-3 cursor-pointer"
          onClick={() => setShowDetails(!showDetails)}
        >
          {translation.pages.order?.paymentContainer.seeDetails ??
            "See Details"}
        </p>
        {showDetails && unitPriceResponse && (
          <>
            <PaymentDetails
              title=""
              priceUnit={translation.general.priceUnit ?? ""}
              isCompany={user.isCompany}
              paymentRows={unitPriceResponse.firstPayment.rows}
              translation={translation}
            />
            <PaymentDetails
              title={translation.pages.order?.paymentContainer.nextDraw}
              priceUnit={translation.general.priceUnit ?? ""}
              isCompany={user.isCompany}
              paymentRows={unitPriceResponse.nextPayment.rows}
              translation={translation}
            />

            <PaymentDetails
              title={
                translation.pages.order?.paymentContainer.regularMonthlyCost
              }
              priceUnit={translation.general.priceUnit ?? ""}
              isCompany={user.isCompany}
              paymentRows={unitPriceResponse.normalPayment}
              translation={translation}
            />
          </>
        )}
      </div>
      <div className="pt-5 pb-2">
        <div className="inline-flex w-full font-bold justify-between">
          <p className="textSmall">
            {translation.pages.order?.paymentContainer.priceNextMonth ??
              "Price next month"}
          </p>
          <p className="textSmall">
            {unitPriceResponse &&
              unitPriceResponse.nextPayment.rows.length > 0 &&
              `${parseFloat(
                unitPriceResponse.nextPayment.rows.slice(-1)[0].total.toString()
              ).toFixed(2)} ${translation.general.priceUnit} ${user.isCompany
                ? translation.pages.order?.paymentContainer.excludingVat
                : ""
              }`}
          </p>
        </div>

        <div className="inline-flex w-full justify-between">
          <p className="textSmall">
            {translation.pages.order?.paymentContainer.ordinaryPriceMonthly ??
              "Ordinary price monthly"}
          </p>
          <p className="textSmall">
            {unitPriceResponse &&
              `${parseFloat(
                unitPriceResponse.normalPayment[3].total.toString()
              ).toFixed(2)} ${translation.general.priceUnit} ${user.isCompany
                ? translation.pages.order?.paymentContainer.excludingVat
                : ""
              }`}
          </p>
        </div>
        {/* TODO: Only show if user has selected
        <p>
          {translation.pages.order?.paymentContainer.paymentMethodInvoice ??
            "Sent as an E-invoice"}
        </p> */}
      </div>
      <div className="inline-flex w-full justify-between">
        {contentType !== "orderStorageStep3Register" && (
          <PaymentButton
            contentType={contentType}
            validation={validation}
            orderFlowUrls={orderFlowUrls}
            country={pageData.requestContext.country}
            groupName={pageData.properties.groupName}
            siteId={siteId}
            sizeCodeId={sizeCodeId}
            translation={translation}
            unitId={unit.unitId}
            reservationTimeoutTimestamp={
              pageData.properties.reservationTimeoutTimestamp
            }
          />
        )}
      </div>
    </div>
  );
}

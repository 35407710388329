'use client';

import { clientLogError, clientLogInfo, clientLogWarn } from '@/app/helpers/clientLoggingHelper';
import LinkElement from '../General/LinkElement';
import { eChevronPosition } from '@/app/models/enum/chevronPosition';
import { eButtonColorTheme } from '@/app/models/enum/eButtonColorTheme';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { PaymentorderResponseRoot } from '@/app/models/apiModels/startPaymentResponse';
import { useShallow } from "zustand/react/shallow";
import useStore from "@/stores/use-store";
import { doUseOrderStorageStore } from "@/stores/order-storage-store";
import { PaymentPaidResponse } from '@/app/models/apiModels/paymentPaidResponse';
import { iChangeCreditCardRequestModel } from '@/app/models/apiModels/requestModels/changeCreditCardRequestModel';
import Loader from '../Loader/Loader';
import { set } from 'lodash';

interface Props {
    changePaymentUrl: string;
    myPagesUrl: string;
    translations: any;
    country: string;
    isCompany: boolean;
    language: string;
    searchParams: { [key: string]: string | string[] | undefined };
    firstMessageTitle: string;
    firstMessageText: string;
    firstMessageButtonText: string;
    completeMessageTitle: string;
    completeMessageText: string;
    completeMessageButtonText: string;
    failedMessageTitle: string;
    failedMessageText: string;
    failedMessageButtonText: string;
    isLoggedIn: boolean | undefined;
    loginUrl: string;
    acceptedPaymentCardsImageUrls: string[] | undefined;
}

export default function ChangePayment({
    changePaymentUrl,
    myPagesUrl,
    translations,
    country,
    language,
    isCompany,
    searchParams,
    firstMessageTitle,
    firstMessageText,
    firstMessageButtonText,
    completeMessageTitle,
    completeMessageText,
    completeMessageButtonText,
    failedMessageTitle,
    failedMessageText,
    failedMessageButtonText,
    isLoggedIn,
    loginUrl,
    acceptedPaymentCardsImageUrls
}: Props) {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [paymentOrderId, setPaymentOrderId] = useState<string>("");
    const [redirectCheckoutHref, setRedirectCheckoutHref] = useState<string>("");
    const [sessionStatus, setSessionStatus] = useState<string>(searchParams?.sessionStatus as string);
    const [isPaymentCompleted, setIsPaymentCompleted] = useState<boolean>();
    const [isPaymentFailedOrCancelled, setIsPaymentFailedOrCancelled] = useState<boolean>();
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
    const [isWatingForConfirmation, setIsWatingForConfirmation] = useState<boolean>();
    const biglocalstorage = doUseOrderStorageStore(useShallow((state) => state));
    const [isCreditCardChanged, setIsCreditCardChanged] = useState<boolean>(false);
    const [errorDetected, setErrorDetected] = useState<boolean>(false);
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isChangeConfirmed, setIsChangeConfirmed] = useState<boolean>(false);
    const [changedCardResponse, setChangedCardResponse] = useState<PaymentPaidResponse>();
    // const reset = doUseOrderStorageStore(useShallow((state) => state.reset));

    const setValue = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.setCreditCardValue;
    const reset = useStore(
        doUseOrderStorageStore,
        useShallow((state) => state)
    )?.resetCreditCard;

    useEffect(() => {
        if ((sessionStatus === "waiting" || sessionStatus === "failed") && (isLoggedIn === undefined || isLoggedIn === false)) {
            clientLogWarn("ChangePayment.tsx", "NOT LOGGED IN", "REFRESHING", "sessionStatus", sessionStatus, "isLoggedIn", isLoggedIn);
            const sessionCookie = getCookie("gsSessionId");
            clientLogInfo("ChangePayment.tsx", "useEffect []", "Session cookie", "sessionCookie", sessionCookie);
            location.reload();
        } else if (sessionStatus === "empty" && (isLoggedIn === undefined || !isLoggedIn)) {
            clientLogWarn("ChangePayment.tsx", "useEffect []", "User is not logged in");
            router.push(loginUrl);
        } else {
            setIsFirstLoad((!sessionStatus || sessionStatus === "" || sessionStatus === "notStarted") && sessionStatus !== "waiting" && sessionStatus !== "completed" && sessionStatus !== "failed");
            setIsWatingForConfirmation(sessionStatus === "waiting");
            setIsPaymentCompleted(sessionStatus === "completed");
            setIsPaymentFailedOrCancelled(sessionStatus === "failed");
        }
    }, [sessionStatus, isLoggedIn]);

    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts?.pop()?.split(';').shift();
    };

    useEffect(() => {
        if ((sessionStatus === "completed" || sessionStatus === "waiting" || sessionStatus === "failed") && isLoggedIn === undefined) {
            clientLogWarn("ChangePayment.tsx", "NOT LOGGED IN", "REFRESHING", "sessionStatus", sessionStatus, "isLoggedIn", isLoggedIn);
            const sessionCookie = getCookie("gsSessionId");
            clientLogInfo("ChangePayment.tsx", "useEffect []", "Session cookie", "sessionCookie", sessionCookie);
            router.refresh();
        }
        if (sessionStatus === "empty" && isLoggedIn === undefined) {
            clientLogWarn("ChangePayment.tsx", "useEffect []", "User is not logged in");
            router.push(loginUrl);
        }
    }, [sessionStatus]);

    useEffect(() => {
        if (isPaymentCompleted) {
            setIsLoading(false);
        }
    }, [isPaymentCompleted])

    useEffect(() => {
        if (isFirstLoad) {
            setIsLoading(false);
            if (searchParams.ui) {
                const uniqueIdentifier = searchParams.ui as string;
                setValue?.("uniqueIdentifier", uniqueIdentifier);
            }

        }
    }, [isFirstLoad]);

    useEffect(() => {
        if (isWatingForConfirmation) {
            setIsLoading(true);
            if (isWatingForConfirmation) {
                clientLogInfo("ChangePayment.tsx", "startpayment", "Payment is completed", "paymentOrderId", biglocalstorage.creditCard.paymentOrderId);

                if (isLoggedIn && sessionStatus === "waiting" && biglocalstorage.creditCard.paymentOrderId !== "") {
                    setIsCreditCardChanged(true);
                }
            }
        }
    }, [isWatingForConfirmation]);

    useEffect(() => {
        if (isPaymentFailedOrCancelled) {
            clientLogError("ChangePayment.tsx", "startpayment", "Payment failed or cancelled");
            setIsLoading(false);
        }
    }, [isPaymentFailedOrCancelled]);

    useEffect(() => {
        if (isCreditCardChanged) {
            creditCardChangedSubmit(biglocalstorage.creditCard.paymentOrderId);
        }
    }, [isCreditCardChanged]);

    useEffect(() => {
        if (redirectCheckoutHref !== "") {
            setIsLoading(true);
            router.push(redirectCheckoutHref);
        }
    }, [redirectCheckoutHref]);

    useEffect(() => {
        if (isChangeConfirmed && changedCardResponse) {
            changeCreditCard(changedCardResponse);
        }
    }, [isChangeConfirmed, changedCardResponse]);

    const initError = (title: string = "", message: string = "") => {
        if (title === "") {
            title = failedMessageTitle || translations.general.errors.e500.title;
        }

        if (message === "") {
            message = failedMessageText || translations.general.errors.e500.message;
        }

        setErrorTitle(title);
        setErrorMessage(message);
        setErrorDetected(true);
        setIsLoading(false);

        if (reset) {
            reset();
        }
    }

    const hideError = () => {
        setErrorDetected(false);
        setErrorTitle("");
        setErrorMessage("");
    }

    const handleChangePaymentClick = () => {
        startChangeCreditCard();
    }

    const startChangeCreditCard = async () => {
        setIsLoading(true);
        hideError();

        try {
            clientLogInfo("ChangePayment.tsx", "startChangeCreditCard", "Starting change credit card", "usedId", biglocalstorage.creditCard.uniqueIdentifier);
            const formData = {
                hostName: `https://${window.location.host}/${country}`,
                isCompany: isCompany,
                payeeReference: `CHA00${Math.floor((Math.random() * (12345678910 - 1234567891 + 1)) + 1234567891)
                    .toString()
                    .replace(/[-:.TZ]/g, "")
                    .slice(2, 16)}`,
                purchaseInformation: translations.pages.changePaymentPage.purchaseInformation,
                userAgent: navigator.userAgent,
                country: country,
                language: language
            };

            const response = await fetch("/api/pay/addCreditCard", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Country: country,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                clientLogError("ChangePayment.tsx", "startChangeCreditCard", "Network response was not ok");
                throw new Error("Network response was not ok");
            }

            const paymentOrder: PaymentorderResponseRoot = await response.json();
            setPaymentOrderId(paymentOrder.paymentorder.id);
            setValue?.("paymentOrderId", paymentOrder.paymentorder.id);

            const redirectCheckoutOperation = paymentOrder.operations.find(
                (operation) => operation.rel === 'redirect-checkout'
            );

            if (redirectCheckoutOperation) {
                clientLogInfo("ChangePayment.tsx", "startChangeCreditCard", "Redirecting to checkout", "href", redirectCheckoutOperation.href);
                setRedirectCheckoutHref(redirectCheckoutOperation.href);
            } else {
                clientLogError("ChangePayment.tsx", "startChangeCreditCard", "Redirect checkout operation not found");
                setIsLoading(false);
                initError();
            }
        } catch (error) {
            clientLogError("ChangePayment.tsx", "startChangeCreditCard", error);
            initError();

            setIsLoading(false);
        }
    };

    const creditCardChangedSubmit = async (paymentIdUrl: string) => {
        clientLogInfo("ChangePayment.tsx", "creditCardChangedSubmit", "Checking if change is made", "paymentIdUrl", paymentIdUrl);
        if (!paymentIdUrl || paymentIdUrl === "") {
            clientLogError("ChangePayment.tsx", "creditCardChangedSubmit", "PaymentIdUrl is empty");
            initError();
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch("/api/pay/paidPaymentByIdUrl", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Country: country,
                    PaymentIdUrl: paymentIdUrl,
                },
            });

            if (!response.ok) {
                initError();
                clientLogError("ChangePayment.tsx", "creditCardChangedSubmit", "Network response was not ok");
            }
            const data: PaymentPaidResponse = await response.json();

            if (!data.paid) {
                clientLogError("ChangePayment.tsx", "creditCardChangedSubmit", "Change is not made");
                initError();
            }

            setIsChangeConfirmed(true);
            setChangedCardResponse(data);
        } catch (error) {
            clientLogError("ChangePayment.tsx", "creditCardChangedSubmit", error);
            initError();
        }
    };

    const changeCreditCard = async (paymentPaidResponse: PaymentPaidResponse | null) => {
        clientLogInfo("ChangePayment.tsx", "changeCreditCard", "Changing credit card", "paymentPaidResponse", paymentPaidResponse);
        let cardData: iChangeCreditCardRequestModel | undefined;

        if (paymentPaidResponse) {
            cardData = {
                identityNumber: biglocalstorage.creditCard.uniqueIdentifier,
                cardExpire: paymentPaidResponse.paid.tokens[0].expiryDate,
                cardNumber: paymentPaidResponse.paid.tokens[0].name,
                recurrenceToken: paymentPaidResponse.paid.tokens[0].token,
            };
        }

        clientLogInfo("ChangePayment.tsx", "changeCreditCard", "Sending change credit card request", "identityNumber", cardData?.identityNumber, "recurrenceToken", cardData?.recurrenceToken);

        const cardResponse = await fetch("/api/checkout/changeCreditCard", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Country: country,
            },
            body: JSON.stringify(cardData),
        });

        if (!cardResponse.ok) {
            initError();
            clientLogError("ChangePayment.tsx", "changeCreditCard", "Network response was not ok");
        }

        const updateSuccess = await cardResponse.json();

        if (updateSuccess) {
            clientLogInfo("ChangePayment.tsx", "changeCreditCard", "Credit card changed successfully");
            if (reset) {
                clientLogInfo("ChangePayment.tsx", "changeCreditCard", "Resetting local storage values");
                reset();
            }
            clientLogInfo("ChangePayment.tsx", "changeCreditCard", "Redirecting with sessionStatus", "sessionStatus", "completed");
            setIsPaymentCompleted(true);
            router.push(`${changePaymentUrl}?sessionStatus=completed`);
        } else {
            initError();
            clientLogError("ChangePayment.tsx", "changeCreditCard", "Credit card change failed");
        }
    };

    const handleBackToMyPagesClick = () => {
        router.push(myPagesUrl);
    }

    return (
        <div>
            <Loader loading={isLoading} useFullWindow={true} />
            <div className="rounded-3xl bg-white lg:w-[400px] p-10 mb-10 mx-auto">
                {errorDetected ? (
                    <div>
                        <h2>{errorTitle}</h2>
                        <div className="mb-8">{errorMessage}</div>
                        <LinkElement
                            title={failedMessageButtonText}
                            buttonColorTheme={eButtonColorTheme.Green}
                            chevronPosition={eChevronPosition.None}
                            onClick={handleBackToMyPagesClick}
                            isButton={true}
                            className="w-full"
                        >
                            {failedMessageButtonText}
                        </LinkElement>
                    </div>
                ) : (<>
                    {isFirstLoad && (
                        <div>
                            <h2>{firstMessageTitle}</h2>
                            <div className="mb-8">{firstMessageText}</div>
                            <LinkElement
                                title={firstMessageButtonText}
                                buttonColorTheme={eButtonColorTheme.Green}
                                chevronPosition={eChevronPosition.None}
                                onClick={handleChangePaymentClick}
                                isButton={true}
                                className="w-full"
                            >
                                {firstMessageButtonText}
                            </LinkElement>
                            {acceptedPaymentCardsImageUrls && (
                                <div className={`flex justify-center space-x-2 mt-5`}>
                                    {acceptedPaymentCardsImageUrls?.map((imageUrl, index) => (
                                        <img
                                            key={index}
                                            src={imageUrl}
                                            className={`inline-block max-h-[24px]`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    {isPaymentCompleted && (
                        <div>
                            <h2>{completeMessageTitle}</h2>
                            <div className="mb-8">{completeMessageText}</div>
                            <LinkElement
                                title={completeMessageButtonText}
                                buttonColorTheme={eButtonColorTheme.Green}
                                chevronPosition={eChevronPosition.None}
                                onClick={handleBackToMyPagesClick}
                                isButton={true}
                                className="w-full"
                            >
                                {completeMessageButtonText}
                            </LinkElement>
                        </div>
                    )}

                    {isPaymentFailedOrCancelled && (
                        <div>
                            <h2>{failedMessageTitle}</h2>
                            <div className="mb-8">{failedMessageText}</div>
                            <LinkElement
                                title={failedMessageButtonText}
                                buttonColorTheme={eButtonColorTheme.Green}
                                chevronPosition={eChevronPosition.None}
                                onClick={handleBackToMyPagesClick}
                                isButton={true}
                                className="w-full"
                            >
                                {failedMessageButtonText}
                            </LinkElement>
                        </div>
                    )}
                </>)}
            </div>
        </div>
    );

}
